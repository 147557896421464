import React from 'react';

const FormInput = ({ handleChange, label, ...props }) => {
  return (
    <div className="form-group">
      <div className="form-label-group">
        {label ? (<label className="form-label" htmlFor="default-01" key={props.key}>{label}</label>) : ''}
      </div>
      <input className="form-control form-control-lg" onChange={handleChange} {...props} />
    </div>

  )
};

export default FormInput;

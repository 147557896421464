import { takeLatest, put, all, call, select } from 'redux-saga/effects';
import cognitoUtils from '../../utils/cognitoUtils';
import SessionActionTypes from './session.types';

import {
  setSessionSuccess
} from './session.actions';

// Set Session
export function* setSession({ payload: callbackHref }) {
  try {
    const state = yield select();
    yield cognitoUtils.parseCognitoWebResponse(callbackHref, state.session.aws); // parse the callback URL
    const session = yield cognitoUtils.getCognitoSession(state.session.aws); // get a new session
    yield put(setSessionSuccess(session));
  } catch (error) {
    console.log('ERROR', error)
    // yield put(signInFailure(error));
  }
}

export function* onSessionStart() {
  yield takeLatest(SessionActionTypes.START_SET_SESSION, setSession);
}

// Clear Session
export function* clearSession() {
  try {
    yield cognitoUtils.signOutCognitoSession();
    // yield put(clearSessionSuccess());
  } catch (error) {
    console.log('ERROR', error)
    // yield put(signInFailure(error));
  }
}

export function* onSessionClear() {
  yield takeLatest(SessionActionTypes.CLEAR_SESSION, clearSession);
}

export function* sessionSagas() {
  yield all([call(onSessionStart), call(onSessionClear)]);
}

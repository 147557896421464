import React from 'react';
import RegisterForm from '../../containers/register-form/register-form.component';
import logo from '../../assets/media/logo-trans.png';

const Register = () => {
  return (
    <div className="nk-app-root">
      <div className="nk-main ">
        <div className="nk-wrap nk-wrap-nosidebar">
          <div className="nk-content ">
            <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
              <div className="brand-logo pb-4 text-center">
                <a href="html/index.html" className="logo-link">
                  <img className="logo-light logo-img logo-img-lg" src={logo} alt="logo" />
                  <img className="logo-dark logo-img logo-img-lg" src={logo} alt="logo-dark" />
                </a>
              </div>
              <div className="card card-bordered">
                <div className="card-inner card-inner-lg">
                  <div className="nk-block-head">
                    <div className="nk-block-head-content">
                      <h4 className="nk-block-title">Register</h4>
                      <div className="nk-block-des">
                        <p>Create New TXTN Account</p>
                      </div>
                    </div>
                  </div>
                  <RegisterForm />
                  <div className="form-note-s2 text-center pt-4"> Already have an account? <a href="html/pages/auths/auth-register-v2.html">Sign in instead</a>
                  </div>
                  {/* <div className="text-center pt-4 pb-3">
                    <h6 className="overline-title overline-title-sap"><span>OR</span></h6>
                  </div>
                  <ul className="nav justify-center gx-4">
                    <li className="nav-item"><a className="nav-link" href="#">Facebook</a></li>
                    <li className="nav-item"><a className="nav-link" href="#">Google</a></li>
                  </ul> */}
                </div>
              </div>
            </div>
            {/* <div className="nk-footer nk-auth-footer-full">
              <div className="container wide-lg">
                <div className="row g-3">
                  <div className="col-lg-6 order-lg-last">
                    <ul className="nav nav-sm justify-content-center justify-content-lg-end">
                      <li className="nav-item">
                        <a className="nav-link" href="#">Terms & Condition</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">Privacy Policy</a>
                      </li>
                      <li className="nav-item">
                        <a className="nav-link" href="#">Help</a>
                      </li>
                      <li className="nav-item dropup">
                        <a className="dropdown-toggle dropdown-indicator has-indicator nav-link" data-toggle="dropdown" data-offset="0,10"><span>English</span></a>
                        <div className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                          <ul className="language-list">
                            <li>
                              <a href="#" className="language-item">
                                <img src="./images/flags/english.png" alt="" className="language-flag" />
                                <span className="language-name">English</span>
                              </a>
                            </li>
                            <li>
                              <a href="#" className="language-item">
                                <img src="./images/flags/spanish.png" alt="" className="language-flag" />
                                <span className="language-name">Español</span>
                              </a>
                            </li>
                            <li>
                              <a href="#" className="language-item">
                                <img src="./images/flags/french.png" alt="" className="language-flag" />
                                <span className="language-name">Français</span>
                              </a>
                            </li>
                            <li>
                              <a href="#" className="language-item">
                                <img src="./images/flags/turkey.png" alt="" className="language-flag" />
                                <span className="language-name">Türkçe</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-6">
                    <div className="nk-block-content text-center text-lg-left">
                      <p className="text-soft">&copy; 2019 CryptoLite. All Rights Reserved.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';

import {
  selectCurrentContactMessages,
  selectCurrentId,
} from 'redux/widget-messages/widget-messages.selectors';
import { selectEmployeesById } from 'redux/employee/employee.selectors';
import { selectWidgetContactsbyId } from 'redux/widget-contact/widget-contact.selectors';
import { selectShowWidgetChat } from 'redux/ui/ui.selectors';
import WidgetMessageChat from 'components/widget-message-chat/widget-message-chat.component';

import {
  setShowChatBody,
} from 'redux/ui/ui.actions';

const MessageConversation = (props) => {
  let messagesEnd;
  const scrollToBottom = () => {
    if (messagesEnd)
      messagesEnd.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  });

  if (!props.currentId || !props.contacts) return (<span></span>);

  const currentId = props.currentId;
  const currentContact = props.contacts[currentId];

  const loopMessages = () => {
    let ml = [];
    if (props.messages) {
      const messageLength = props.messages.length;
      for (let i = 0; i < messageLength; i++) {
        const message = props.messages[i];
        let showMeta = true;
        if (i < messageLength - 1) {
          let nextMessage = props.messages[i + 1];
          if (message.incomingMessage === nextMessage.incomingMessage) showMeta = false;
        }
        ml.push(
          <span key={message.createdDate}>
            {!message.incomingMessage
              ? employeeMessage(
                message.input,
                message.userId,
                message.createdDate,
                showMeta
              )
              : contactMessage(message.input, null, message.createdDate, showMeta)}
          </span>
        )
      }
    }
    return ml;
  };


  const hideChatBody = () => {
    // Hide chat
    props.setShowChatBody(false)
  }

  const employeeMessage = (currentinput, employeeId, eventdate, showMeta, key) => {
    const employeeName = props.employeesById[employeeId]
      ? props.employeesById[employeeId].email
      : 'Unknown';
    const md = (
      <div className="chat is-me" key={key}>
        <div className="chat-content">
          <div className="chat-bubbles">
            <div className="chat-bubble">
              <div className="chat-msg">{currentinput}</div>

            </div>
          </div>
          <ul className="chat-meta">
            <li>{employeeName}</li>
            <li>{moment(eventdate).calendar()}</li>
          </ul>
        </div>
      </div>
    );
    return md;
  };

  const contactMessage = (currentinput, useridentifier, eventdate, showMeta, key) => {
    const md = (
      <div className="chat is-you" key={key}>
        <div className="chat-avatar">
          <div className="user-avatar bg-purple">
            <span>{currentContact.name ? currentContact.name[0] : 'UN'}</span>
          </div>
        </div>
        <div className="chat-content">
          <div className="chat-bubbles">
            <div className="chat-bubble">
              <div className="chat-msg"> {currentinput} </div>
            </div>
          </div>
          <ul className="chat-meta">
            {/* <li>{useridentifier}</li> */}
            <li>{moment(eventdate).calendar()}</li>
          </ul>
        </div>
      </div>
    );
    return md;
  };

  return (
    <div className={`nk-chat-body ${props.selectShowWidgetChat ? ' show-chat' : ''}`}>
      <div className="nk-chat-head">
        <ul className="nk-chat-head-info">
          <li className="nk-chat-body-close">
            <button onClick={hideChatBody} className="btn btn-icon btn-trigger nk-chat-hide ml-n1"><em className="icon ni ni-arrow-left"></em></button>
          </li>
          <li className="nk-chat-head-user">
            <div className="user-card">
              <div className="user-avatar bg-purple">
                <span>{currentContact.name ? currentContact.name[0] : 'UN'}</span>
              </div>
              <div className="user-info">
                <div className="lead-text">{currentId
                  ? `To: ${currentContact.name ? currentContact.name : 'Unknown Name'}`
                  : 'Select a number on the left to see messages (If no messages appear try refreshing the page)'}</div>
                <span className="sub-text">{currentId
                  ? `${currentId}`
                  : ''}</span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div className="nk-chat-panel" data-simplebar>
        <div>
          {loopMessages()}
        </div>
        <div
          style={{ float: 'left', clear: 'both' }}
          ref={(el) => {
            messagesEnd = el;
          }}
        ></div>
      </div>
      <WidgetMessageChat currentContact={currentContact} />
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  messages: selectCurrentContactMessages,
  contacts: selectWidgetContactsbyId,
  currentId: selectCurrentId,
  employeesById: selectEmployeesById,
  selectShowWidgetChat: selectShowWidgetChat
});


const mapDispatchToProps = (dispatch) => ({
  setShowChatBody: (b) => dispatch(setShowChatBody(b))
});

export default connect(
  mapStateToProps, mapDispatchToProps
)(MessageConversation);

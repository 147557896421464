const MessageActionTypes = {
  GET_TEXTS_START: 'GET_TEXTS_START',
  GET_TEXTS_SUCCESS: 'GET_TEXTS_SUCCESS',
  GET_TEXTS_FAILURE: 'GET_TEXTS_FAILURE',
  GET_CONTACTS_TEXTS_START: 'GET_CONTACTS_TEXTS_START',
  GET_CONTACTS_TEXTS_SUCCESS: 'GET_CONTACTS_TEXTS_SUCCESS',
  GET_CONTACTS_TEXTS_FAILURE: 'GET_CONTACTS_TEXTS_FAILURE',
  SET_CURRENT_NUMBER: 'SET_CURRENT_NUMBER',
  NEW_TEXT_WEBSOCKET: 'NEW_TEXT_WEBSOCKET',
  NEW_TEXT_START: 'NEW_TEXT_START',
  NEW_TEXT_SUCCESS: 'NEW_TEXT_SUCCESS',
  NEW_TEXT_FAILURE: 'NEW_TEXT_FAILURE',
  SET_READ_START: 'SET_READ_START',
  SET_READ_SUCCESS: 'SET_READ_SUCCESS',
  SET_READ_FAILURE: 'SET_READ_FAILURE'
};

export default MessageActionTypes;

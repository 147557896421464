import React from 'react';
import { useSelector } from 'react-redux';
import {
  Switch,
  Redirect,
  useRouteMatch,
  Route,
  Link,
  useLocation
} from 'react-router-dom';

import PersonalInfo from '../../containers/AccountSettings/PersonalInfo';
import Notifications from '../../containers/AccountSettings/Notifications';
import Settings from '../../containers/AccountSettings/Settings';

const AccountSettings = () => {
  const { user } = useSelector(({ auth }) => ({
    user: auth.user,
  }));
  let { path, url } = useRouteMatch();
  const location = useLocation();
  return (
    <div className="nk-content ">
      <div className="container-fluid">
        <div className="nk-content-inner">
          <div className="nk-content-body">
            <div className="nk-block">
              <div className="card card-bordered">
                <div className="card-aside-wrap">
                  <div className="card-inner card-inner-lg">
                    <Switch>
                      <Route path={`${path}/personal-info`}>
                        <PersonalInfo />
                      </Route>
                      <Route path={`${path}/notifications`}>
                        <Notifications />
                      </Route>
                      <Route path={`${path}/settings`}>
                        <Settings />
                      </Route>
                      <Redirect to={`${path}/personal-info`} />
                    </Switch>
                  </div>
                  <div className="card-aside card-aside-left user-aside toggle-slide toggle-slide-left toggle-break-lg toggle-screen-lg" data-content="userAside" data-toggle-screen="lg" data-toggle-overlay="true">
                    <div className="card-inner-group" data-simplebar="init">
                      <div className="simplebar-wrapper" style={{ margin: "0px" }}>
                        <div className="simplebar-height-auto-observer-wrapper">
                          <div className="simplebar-height-auto-observer"></div>
                        </div>
                        <div className="simplebar-mask">
                          <div className="simplebar-offset" style={{ right: '0px', bottom: '0px' }}>
                            <div className="simplebar-content-wrapper" style={{ height: "auto", overflow: "hidden" }}>
                              <div className="simplebar-content" style={{ padding: "0px" }}>
                                <div className="card-inner">
                                  <div className="user-card">
                                    <div className="user-avatar bg-primary">
                                      <em className="icon ni ni-user-alt"></em>
                                    </div>
                                    <div className="user-info"><span className="lead-text">{user.email}</span></div>
                                  </div>
                                </div>
                                <div className="card-inner p-0">
                                  <ul className="link-list-menu">
                                    <li>
                                      <Link className={`${location.pathname === '/account-settings/personal-info' ? 'active' : ''}`} to={`${url}/personal-info`}>
                                        <em className="icon ni ni-user-fill-c"></em>
                                        <span>Personal Infomation</span>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link className={`${location.pathname === '/account-settings/notifications' ? 'active' : ''}`} to={`${url}/notifications`}>
                                        <em className="icon ni ni-bell-fill"></em>
                                        <span>Notifications</span>
                                      </Link>
                                    </li>
                                    <li>
                                      <Link className={`${location.pathname === '/account-settings/settings' ? 'active' : ''}`} to={`${url}/settings`}>
                                        <em className="icon ni ni-account-setting"></em>
                                        <span>Settings</span>
                                      </Link>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="simplebar-placeholder" style={{ width: "auto", height: "550px" }}></div>
                      </div>
                      <div className="simplebar-track simplebar-horizontal" style={{ visibility: "hidden" }}>
                        <div className="simplebar-scrollbar" style={{ width: "0px", display: "none" }}></div>
                      </div>
                      <div className="simplebar-track simplebar-vertical" style={{ visibility: "hidden" }}>
                        <div className="simplebar-scrollbar" style={{ height: "0px", display: "none" }}></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  );
}

export default AccountSettings;

import UserActionTypes from './user.types';

export const signInStart = (emailAndPassword) => ({
  type: UserActionTypes.SIGN_IN_START,
  payload: emailAndPassword,
});

export const signInSuccess = (user) => ({
  type: UserActionTypes.SIGN_IN_SUCCESS,
  payload: user,
});

export const signInFailure = (error) => ({
  type: UserActionTypes.SIGN_IN_FAILURE,
  payload: error,
});

export const signOutStart = () => ({
  type: UserActionTypes.SIGN_OUT_START,
});

export const signOutSuccess = () => ({
  type: UserActionTypes.SIGN_OUT_SUCCESS,
});

export const signOutFailure = (error) => ({
  type: UserActionTypes.SIGN_OUT_FAILURE,
  payload: error,
});

export const checkUserSession = () => ({
  type: UserActionTypes.CHECK_USER_SESSION
});

export const fulfillUser = (user) => ({
  type: UserActionTypes.USER_LOADED,
  payload: { user },
});

export const generateTwilioToken = () => ({
  type: UserActionTypes.GENERATE_TWILIO_TOKEN
});

export const fulfillTwilioToken = (token) => ({
  type: UserActionTypes.TWILIO_TOKEN_LOADED,
  payload: { token },
});

export const toggleOrgOnlineStart = (b) => ({
  type: UserActionTypes.TOGGLE_ORG_ONLINE_START,
  payload: b
});

export const toggleOrgOnlineSuccess = (b) => ({
  type: UserActionTypes.TOGGLE_ORG_ONLINE_SUCCESS,
  payload: b
});

export const toggleOrgOnlineFailure = (error) => ({
  type: UserActionTypes.TOGGLE_ORG_ONLINE_FAILURE,
  payload: error,
});



import EmployeeActionTypes from './employee.types';

const INITIAL_STATE = {
  error: null,
};

const employeeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EmployeeActionTypes.GET_EMPLOYEES_START:
      return {
        ...state,
        isFetching: true,
      };
    case EmployeeActionTypes.GET_EMPLOYEES_SUCCESS: {
      const pl = action.payload;
      const itemsById = pl.reduce(
        (result, item) => ({
          ...result,
          [item['id']]: item,
        }),
        {}
      );
      return {
        ...state,
        items: pl,
        itemsById,
        isFetching: false,
        error: null,
      };
    }
    case EmployeeActionTypes.GET_EMPLOYEES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default employeeReducer;

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectEnterToCall } from '../../redux/ui/ui.selectors';
import { setEnterToCall } from '../../redux/ui/ui.actions';

const Settings = (props) => {
  const handleClick = (event) => {
    const target = event.target;
    const checked = target.checked;
    props.setEnterToCall(checked)
  };

  return (
    <React.Fragment>
      <div className="nk-block-head nk-block-head-lg">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">Settings</h4>
            <div className="nk-block-des">
              <p>All other account settings.</p>
            </div>
          </div>
          <div className="nk-block-head-content align-self-start d-lg-none">
          </div>
        </div>
      </div>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-head-content">
          <h6>Other</h6>
        </div>
      </div>
      <div className="nk-block-content">
        <div className="gy-3">
          <div className="g-item">
            <div className="custom-control custom-switch">
              <input type="checkbox" className="custom-control-input" id="unusual-activity" checked={props.selectEnterToCall ? true : false} onClick={handleClick} />
              <label className="custom-control-label" for="unusual-activity">Press enter to start a call</label>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
}

const mapStateToProps = createStructuredSelector({
  selectEnterToCall: selectEnterToCall
});

const mapDispatchToProps = (dispatch) => ({
  setEnterToCall: (b) => dispatch(setEnterToCall(b))
});

export default connect(
  mapStateToProps, mapDispatchToProps
)(Settings);
import EmployeeActionTypes from './employee.types';

export const getEmployeesStart = () => ({
  type: EmployeeActionTypes.GET_EMPLOYEES_START,
});

export const getEmployeesSuccess = (employees) => ({
  type: EmployeeActionTypes.GET_EMPLOYEES_SUCCESS,
  payload: employees,
});

export const getEmployeesFailure = (error) => ({
  type: EmployeeActionTypes.GET_EMPLOYEES_FAILURE,
  payload: error,
});

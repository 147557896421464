import { createSelector } from 'reselect';

const selectUI = (state) => state.ui;

export const selectShowChat = createSelector(
  [selectUI],
  (ui) => ui.showChat
);

export const selectShowWidgetChat = createSelector(
  [selectUI],
  (ui) => ui.showWidgetChat
);

export const selectBrowserNotifications = createSelector(
  [selectUI],
  (ui) => ui.browserNotifications
);

export const selectSoundNotifications = createSelector(
  [selectUI],
  (ui) => ui.soundNotifications
);

export const selectEnterToCall = createSelector(
  [selectUI],
  (ui) => ui.enterToCall
);
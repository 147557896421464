import { takeLatest, put, all, call } from 'redux-saga/effects';
import axios from 'axios';

import MessageActionTypes from './messages.types';

import {
  getTextsSuccess,
  getTextsFailure,
  getContactTextsSuccess,
  getContactTextsFailure,
  newTextSuccess,
  newTextFailure,
  setReadSuccess,
  setReadFailure
} from './messages.actions';

const URL = process.env.REACT_APP_API_URL;

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function* getTexts() {
  try {
    const { data } = yield axios.get(`${URL}/texts`);

    yield put(getTextsSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(getTextsFailure(error));
  }
}

export function* getContactTexts(pl) {
  const { payload } = pl;
  try {
    // const { data } = yield axios.get(`${URL}/texts?contactId=${payload.id}`);
    const r = yield Promise.all([
      axios.get(`${URL}/texts?contactId=${payload.id}`),
      timeout(500)
    ]);
    const { data } = r[0];
    console.log(data)

    yield put(getContactTextsSuccess(payload.phoneNumber, data));
  } catch (error) {
    console.log(error);
    yield put(getContactTextsFailure(error));
  }
}

export function* sendText(item) {
  try {
    const { data } = yield axios.post(`${URL}/texts`, {
      phoneNumber: item.payload.number,
      input: item.payload.message,
      // EmployeeId: item.payload.ymcaUser.id,
    });
    yield put(newTextSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(newTextFailure(error));
  }
}

export function* readText(item) {
  try {
    const { data } = yield axios.post(`${URL}/texts/read`, {
      contactId: item.payload
    });
    yield put(setReadSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(setReadFailure(error));
  }
}

export function* onGetContactTextsStart() {
  yield takeLatest(MessageActionTypes.GET_CONTACTS_TEXTS_START, getContactTexts);
}

export function* onGetTextsStart() {
  yield takeLatest(MessageActionTypes.GET_TEXTS_START, getTexts);
}

export function* onNewTextStart() {
  yield takeLatest(MessageActionTypes.NEW_TEXT_START, sendText);
}

export function* onReadTextStart() {
  yield takeLatest(MessageActionTypes.SET_READ_START, readText)
}

export function* messageSagas() {
  yield all([call(onGetTextsStart), call(onNewTextStart), call(onGetContactTextsStart), call(onReadTextStart)]);
}

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'

import logo from '../../assets/media/logo-small.png';

import { selectCallsAddon, selectWidgetAddon, selectTextsAddon, selectContactsAddon } from 'redux/user/user.selectors';

const SideBar = (props) => {
  const location = useLocation();

  const showPhone = () => {
    if (props.selectCallsAddon)
      return (
        <li className={`nk-menu-item ${location.pathname === '/phone' ? 'active' : ''}`}>
          <Link to="/phone" className="nk-menu-link" title="Phone">
            <span className="nk-menu-icon"><em className="icon ni ni-call-alt"></em></span>
          </Link>
        </li>
      )
  }

  const showWidgetChat = () => {
    if (props.selectWidgetAddon)
      return (
        <li className={`nk-menu-item ${location.pathname === '/widget-chat' ? 'active' : ''}`}>
          <Link to="/widget-chat" className="nk-menu-link" title="Widget Chat">
            <span className="nk-menu-icon"><em className="icon ni ni-browser"></em></span>
          </Link>
        </li>
      )
  }

  const showTexts = () => {
    if (props.selectTextsAddon)
      return (
        <li className={`nk-menu-item ${location.pathname === '/chat' ? 'active' : ''}`}>
          <Link to="/chat" className="nk-menu-link" title="Chats">
            <span className="nk-menu-icon"><em className="icon ni ni-chat-circle"></em></span>
          </Link>
        </li>
      )
  }

  const showContacts = () => {
    if (props.selectContactsAddon)
      return (
        <li className={`nk-menu-item ${location.pathname === '/contacts' ? 'active' : ''}`}>
          <Link to="/contacts" className="nk-menu-link" title="Contacts">
            <span className="nk-menu-icon"><em className="icon ni ni-contact"></em></span>
          </Link>
        </li>
      )
  }

  return (
    <div className="nk-apps-sidebar is-dark">
      <div className="nk-apps-brand">
        <Link to="/dashboard" className="logo-link">
          <img className="logo-light logo-img" src={logo}
            alt="logo" />
          <img className="logo-dark logo-img" src={logo}
            alt="logo-dark" />
        </Link>
      </div>
      <div className="nk-sidebar-element">
        <div className="nk-sidebar-body">
          <div className="nk-sidebar-content" data-simplebar>
            <div className="nk-sidebar-menu">
              <ul className="nk-menu apps-menu">
                <li className={`nk-menu-item ${location.pathname === '/dashboard' ? 'active' : ''}`}>
                  <Link to="/dashboard" className="nk-menu-link" title="Dashboard">
                    <span className="nk-menu-icon"><em className="icon ni ni-dashboard"></em></span>
                  </Link>
                </li>
                <li className="nk-menu-hr"></li>
                {showPhone()}
                {showTexts()}
                {showContacts()}
                {showWidgetChat()}
              </ul>
            </div>
          </div>
          <div className="nk-sidebar-footer">
            <ul className="nk-menu nk-menu-md">
              <li className="nk-menu-item">
                <a href="mailto:support@boltonsmith.com" className="nk-menu-link" title="Send Feedback">
                  <span className="nk-menu-icon"><em className="icon ni ni-emails"></em></span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  );
};

const mapStateToProps = createStructuredSelector({
  selectCallsAddon: selectCallsAddon,
  selectWidgetAddon: selectWidgetAddon,
  selectTextsAddon: selectTextsAddon,
  selectContactsAddon: selectContactsAddon
});

export default connect(
  mapStateToProps
)(SideBar);
const WidgetContactActionTypes = {
  GET_WIDGET_CONTACTS_START: 'GET_WIDGET_CONTACTS_START',
  GET_WIDGET_CONTACTS_SUCCESS: 'GET_WIDGET_CONTACTS_SUCCESS',
  GET_WIDGET_CONTACTS_FAILURE: 'GET_WIDGET_CONTACTS_FAILURE',
  SET_CURRENT_WIDGET_CONTACT: 'SET_CURRENT_WIDGET_CONTACT',
  UPDATE_WIDGET_CONTACT_START: 'UPDATE_WIDGET_CONTACT_START',
  UPDATE_WIDGET_CONTACT_SUCCESS: 'UPDATE_WIDGET_CONTACT_SUCCESS',
  UPDATE_WIDGET_CONTACT_FAILURE: 'UPDATE_WIDGET_CONTACT_FAILURE'
};

export default WidgetContactActionTypes;

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Redirect } from 'react-router-dom';
import MessageUserList from '../../containers/message-user-list/message-user-list.component';
import MessageConversation from '../../containers/message-conversation/message-conversation.container';
import NewMessageIcon from '../../components/new-message-icon/new-message-icon';

import { selectShowChat } from '../../redux/ui/ui.selectors';
import { selectTextsAddon } from '../../redux/user/user.selectors';

const Chat = (props) => {
  if (!props.selectTextsAddon) {
    return <Redirect
      to={{
        pathname: '/',
        state: { from: '/chat' },
      }}
    />
  }

  return (
    <div className="nk-content p-0">
      <div className="nk-content-inner">
        <div className="nk-content-body">
          <div className="nk-chat">
            <div className={`nk-chat-aside ${props.selectShowChat ? 'hide-aside' : ''}`}>
              <div className="nk-chat-aside-head">
                <div className="nk-chat-aside-user">
                  <div className="dropdown">
                    <div className="user-avatar">
                      <img src="./images/avatar/b-sm.jpg" alt="" />
                    </div>
                    <div className="title">Chats</div>
                  </div>
                </div>
                <ul className="nk-chat-aside-tools g-2">
                  <li>
                    <NewMessageIcon />
                  </li>
                </ul>
              </div>
              <MessageUserList />
            </div>
            <MessageConversation />
          </div>
        </div>
      </div>

    </div>
  );
}


const mapStateToProps = createStructuredSelector({
  selectShowChat: selectShowChat,
  selectTextsAddon: selectTextsAddon,
});

export default connect(
  mapStateToProps
)(Chat);
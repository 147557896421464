import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { ReactComponent as CallSVG } from '../../assets/media/svg/dashboard/undraw_calling_kpbp.svg';
import { ReactComponent as MessageSVG } from '../../assets/media/svg/dashboard/undraw_Messages_re_qy9x.svg';
import { ReactComponent as ContactsSVG } from '../../assets/media/svg/dashboard/undraw_online_friends_x73e.svg';
import { ReactComponent as WebChatSVG } from '../../assets/media/svg/dashboard/undraw_Chat_bot_re_e2gj.svg';
import { selectUserEmail, selectCallsAddon, selectWidgetAddon, selectTextsAddon, selectContactsAddon, selectUserOrg } from '../../redux/user/user.selectors';
import { CUSTOMIZATION } from "../../ClientCustomization";

class Dashboard extends Component {

  render() {
  
    let org = this.props.selectUserOrg.name;

    const showTextsCard = () => {
      if(!this.props.selectTextsAddon && CUSTOMIZATION.HIDE_UNUSED_CARDS.indexOf(org) >= 0){
        return;
      }

      var action = "";

      if (this.props.selectTextsAddon) {
        action = (
          <Link to="/chat" className="link" title="Chat">
            <span>Texts</span> <em className="icon ni ni-chevron-right"></em>
          </Link>
        )
      } else {
        action = (<button className="link"><span>Contact us to purchase!</span> </button>)
      }

      return (
        <div className="col-md-3">
          <div className="card card-bordered card-full">
            <div className="nk-wg1">
              <div className="nk-wg1-block">
                <div className="nk-wg1-img">
                  <MessageSVG />
                </div>
                <div className="nk-wg1-text">
                  <h5 className="title">Text Chats</h5>
                  <p>Send or receive text messages.</p>
                </div>
              </div>
              <div className="nk-wg1-action">
                {action}
              </div>
            </div>
          </div>
        </div>
      )
    }

    const showPhoneCard = () => {
      if(!this.props.selectCallsAddon && CUSTOMIZATION.HIDE_UNUSED_CARDS.indexOf(org) >= 0){
        return;
      }

      var action = "";

      if (this.props.selectCallsAddon) {
        action = (
          <Link to="/phone" className="link" title="Phone">
            <span>Phone</span> <em className="icon ni ni-chevron-right"></em>
          </Link >
        )
      } else {
        action = (
          <button className="link"><span>Contact us to purchase!</span> </button>
        )
      }

      return (
        <div className="col-md-3">
          <div className="card card-bordered card-full">
            <div className="nk-wg1">
              <div className="nk-wg1-block">
                <div className="nk-wg1-img">
                  <CallSVG />
                </div>
                <div className="nk-wg1-text">
                  <h5 className="title">Phone</h5>
                  <p>Make calls or receive incoming calls.</p>
                </div>
              </div>
              <div className="nk-wg1-action">
                {action}
              </div>
            </div>
          </div>
        </div>
      )
    }

    const showContactsWidget = () => {
      if(!this.props.selectContactsAddon && CUSTOMIZATION.HIDE_UNUSED_CARDS.indexOf(org) >= 0){
        return;
      }

      var action = "";

      if (this.props.selectContactsAddon) {
        action = (
          <Link to="/contacts" className="link" title="Contacts">
            <span>Contacts</span> <em className="icon ni ni-chevron-right"></em>
          </Link>
        )
      } else {
        action = (<button className="link"><span>Contact us to purchase!</span> </button>)
      }

      return (
        <div className="col-md-3">
          <div className="card card-bordered card-full">
            <div className="nk-wg1">
              <div className="nk-wg1-block">
                <div className="nk-wg1-img">
                  <ContactsSVG />
                </div>
                <div className="nk-wg1-text">
                  <h5 className="title">Contacts</h5>
                  <p>Contacts you have send or receieved text messages from.</p>
                </div>
              </div>
              <div className="nk-wg1-action">
                {action}
              </div>
            </div>
          </div>
        </div>
      )
    }

    const showChatWidget = () => {
      if(!this.props.selectWidgetAddon && CUSTOMIZATION.HIDE_UNUSED_CARDS.indexOf(org) >= 0){
        return;
      }

      var action = "";

      if (this.props.selectWidgetAddon) {
        action = (
          <Link to="/widget-chat" className="link" title="Widget Chat">
            <span>Widget Chats</span> <em className="icon ni ni-chevron-right"></em>
          </Link>
        )
      } else {
        action = (<button className="link"><span>Coming Soon...</span> </button>)
      }

      return (
        <div className="col-md-3">
          <div className="card card-bordered card-full">
            <div className="nk-wg1">
              <div className="nk-wg1-block">
                <div className="nk-wg1-img">
                  <WebChatSVG />
                </div>
                <div className="nk-wg1-text">
                  <h5 className="title">Widget Chats</h5>
                  <p>Chats from your website chat widget.</p>
                </div>
              </div>
              <div className="nk-wg1-action">
                {action}
              </div>
            </div>
          </div>
        </div>
      )
    }

    return (
      <div className="nk-content ">
        <div className="container-fluid">
          <div className="nk-content-inner">
            <div className="nk-content-body">
              <div className="nk-content-wrap">
                <div className="nk-block-head nk-block-head-lg">
                  <div className="nk-block-between-md g-4">
                    <div className="nk-block-head-content">
                      <h3 className="nk-block-title fw-normal">Welcome, {this.props.userEmail}</h3>
                      <div className="nk-block-des">
                        <p>Welcome to our dashboard. Navigate to your apps here.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="nk-block">
                  <div className="row g-gs">
                    {showPhoneCard()}
                    {showTextsCard()}
                    {showContactsWidget()}
                    {showChatWidget()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userEmail: selectUserEmail,
  selectCallsAddon,
  selectWidgetAddon,
  selectTextsAddon,
  selectContactsAddon,
  selectUserOrg
});

export default connect(
  mapStateToProps
)(Dashboard);
/**
 * This is a simple object to help tweak small UI changes. 
 * Each tweak is a list of organizations to which the tweak applies 
 * and/or data needed by said tweak.
 */
export const CUSTOMIZATION = {
  "HIDE_UNUSED_CARDS": ["ytrymca"],
  "PHONE_LOGO": {
    "ytrymca":"assets/customization/ytrymca.png"
  }
}
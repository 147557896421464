import { createSelector } from 'reselect';

const selectMessages = (state) => state.messages;

export const selectMessageItems = createSelector(
  [selectMessages],
  (messages) => messages.items
);

export const selectMessagesByUser = createSelector(
  [selectMessages],
  (messages) => messages.itemsByNumber
);

export const selectMessageUsers = createSelector(
  [selectMessages],
  (messages) => messages.itemsByNumber
);

export const selectCurrentNumber = createSelector(
  [selectMessages],
  (messages) => messages.currentNumber
);

export const selectCurrentNumberMessages = createSelector(
  [selectMessages],
  (messages) => messages.itemsByNumber[messages.currentNumber]
);

export const selectMessagesCount = createSelector(
  [selectMessageItems],
  (items) => items.length
);

export const selectIsMessagesFetching = createSelector(
  [selectMessages],
  (messages) => messages.isFetching
);

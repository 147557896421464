import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Redirect } from 'react-router-dom';

import { selectCallsAddon, selectUser } from '../../redux/user/user.selectors';
import { selectEnterToCall } from '../../redux/ui/ui.selectors';
import { CUSTOMIZATION } from 'ClientCustomization';

const PhoneWidget = (props) => {
  const [currentNumber, setCurrentNumber] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState(undefined);

  const _handleKeyDown = (e) => {
    if (e.key === 'Enter' && props.selectEnterToCall) {
      console.log('do validate');
      callCustomer()
    }
  }

  if (!props.selectCallsAddon) {
    return <Redirect
      to={{
        pathname: '/',
        state: { from: '/phone' },
      }}
    />
  }

  const handleChange = (event) => {
    if (props.status === "connect") {
      if (props.sendDigits) {
        props.sendDigits(`${event.currentTarget.innerHTML}`);
      }
      return;
    } else {
      if (!currentNumber || currentNumber.length < 10) {
        setCurrentNumber(`${currentNumber ? currentNumber : ''}${event.currentTarget.innerHTML}`);
      }
    }
  };

  const handleBackspace = (event) => {
    const newNumber = currentNumber.substr(0, currentNumber.length - 1);
    setCurrentNumber(newNumber);
  };

  const callCustomer = () => {
    setErrorMessage(undefined);
    var params = { "phoneNumber": `+1${currentNumber}` };
    console.log('CALLING', params);

    if (currentNumber) {
      if (currentNumber.length !== 10) {
        setErrorMessage('Your number must be 10 numbers long');
        return;
      }
      const regex = new RegExp("^[0-9]+$");
      if (!regex.test(currentNumber)) {
        setErrorMessage('Your number must be numbers only');
        return;
      }
      props.device.connect(params);
    } else {
      setErrorMessage('You must enter a number');
    }

  }

  const hangUp = () => {
    props.device.disconnectAll();
  }

  const handleEditor = (event) => {
    const { value } = event.target;
    setCurrentNumber(value);
  };

  const handleStatus = () => {
    if (!props.user.phoneNumber) return 'You must have a personal number assigned to use this feature. Please contact support!'
    let status = "";
    switch (props.status) {
      case "ready":
        status = "Ready"
        break;
      case "outgoing":
        status = "Outgoing Call"
        break;
      case "incoming":
        status = "Incoming Call"
        break;
      case "error":
        status = "Error: Your connection has timed out. Please refresh your page."
        break;
      default:
        status = props.status;
    }
    return status
  };

  const getLogo = () => {
    if(props.user.organization.name in CUSTOMIZATION.PHONE_LOGO){
      return CUSTOMIZATION.PHONE_LOGO[props.user.organization.name];
    }

    return null;
  }

  return (
    <div className="nk-content">
      <div className="hoverLogo" style={{position: 'fixed', top: "84px", right: "32px", width: '200px', display: `${getLogo() ? 'block' : 'none'}`}}>
        <img src={getLogo()} alt="logo"></img>
      </div>
      <div className="container-fluid">
        <div className="nk-content-inner">
          <div className="nk-content-body">
            <div className="nk-block-head nk-block-head-sm">
              <div className="nk-block-between">
                <div className="nk-block-head-content">
                  <h3 className="nk-block-title page-title">Phone</h3>
                  <div className="nk-block-des text-soft">
                    Status: {handleStatus()}
                  </div>
                </div>
              </div>
            </div>
            <div className="nk-block" style={{ display: `${props.user.phoneNumber ? 'block' : 'none'}` }}>
              <div className="row  justify-content-center">
                <div className="col-sm-8 col-md-6 col-xl-3">
                  <div className="alert-alt alert-danger alert-icon"><em className="icon ni ni-report-fill"></em> <strong>Do not use this phone for 911 calling as the address will not be sent</strong></div>
                  <br />
                  <span>Your Number: {props.user.phoneNumber}</span>
                  <div className="card card-bordered">
                    <div className="card-inner">
                      <span style={{ color: "red" }}>{errorMessage}</span>
                      <div className="form-group">
                        <div className="form-control-wrap">
                          <div className="form-icon form-icon-left">+1</div>
                          <input type="text" className="form-control form-control-lg" id="phone-no" value={currentNumber} maxLength="10" onChange={handleEditor} style={{ color: "grey" }} onKeyDown={_handleKeyDown} />
                        </div>
                      </div>
                      <div className="form-group">
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                          <button className="btn btn-xl btn-primary" onClick={handleChange}>1</button>
                          <button className="btn btn-xl btn-primary" onClick={handleChange}>2</button>
                          <button className="btn btn-xl btn-primary" onClick={handleChange}>3</button>
                        </div>
                      </div>
                      <div className="form-group">
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                          <button className="btn btn-xl btn-primary" onClick={handleChange}>4</button>
                          <button className="btn btn-xl btn-primary" onClick={handleChange}>5</button>
                          <button className="btn btn-xl btn-primary" onClick={handleChange}>6</button>
                        </div>
                      </div>
                      <div className="form-group">
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                          <button className="btn btn-xl btn-primary" onClick={handleChange}>7</button>
                          <button className="btn btn-xl btn-primary" onClick={handleChange}>8</button>
                          <button className="btn btn-xl btn-primary" onClick={handleChange}>9</button>
                        </div>
                      </div>
                      <div className="form-group">
                        <div style={{ display: "flex", justifyContent: "space-around" }}>
                          <button className="btn btn-xl btn-primary" onClick={handleChange}>#</button>
                          <button className="btn btn-xl btn-primary" onClick={handleChange}>0</button>
                          <button className="btn btn-xl btn-primary" onClick={handleBackspace}><em className="icon ni ni-back-ios" style={{ fontSize: "0.8em" }}></em></button>
                        </div>
                      </div>
                      <div className="form-group">
                        <button className="btn btn-lg btn-primary call-support-button btn-block" onClick={callCustomer} hidden={props.status === "connect" || props.status === "incoming"}>Call Number</button>
                        <button className="btn btn-lg btn-danger hangup-button btn-block" onClick={hangUp} hidden={props.status !== "connect"}>Hang up</button>
                        <br />
                        <button className="btn btn-lg btn-success call-support-button btn-block" onClick={props.connectionFunction} hidden={!props.connectionFunction || props.status !== "incoming"}>Answer</button>
                        <button className="btn btn-lg btn-danger call-support-button btn-block" onClick={props.rejectFunction} hidden={!props.rejectFunction || props.status !== "incoming"}>Decline</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >

    </div >
  );
};

const mapStateToProps = createStructuredSelector({
  selectCallsAddon: selectCallsAddon,
  user: selectUser,
  selectEnterToCall: selectEnterToCall
});

export default connect(
  mapStateToProps
)(PhoneWidget);
import { createSelector } from 'reselect';

const selectWC = (state) => state.widgetContacts;

export const selectWidgetContactsbyId = createSelector(
  [selectWC],
  (contacts) => contacts.itemsById
);

export const selectWidgetContacts = createSelector(
  [selectWC],
  (contacts) => {
    let rArray = [];
    for (let n in contacts.itemsById) {
      let c = contacts.itemsById[n];
      if (c.lastMessage) rArray.push(c)
    }
    rArray.sort(function (a, b) { return new Date(b.lastMessage.createdDate) - new Date(a.lastMessage.createdDate) });
    return rArray;
  }
);


// export const selectContactsWithTexts = createSelector(
//   [selectContacts],
//   (contacts) => {
//     let rArray = [];
//     for (let n in contacts.itemsByNumber) {
//       let c = contacts.itemsByNumber[n];
//       if (c.lastText) rArray.push(c)
//     }
//     rArray.sort(function (a, b) { return new Date(b.lastText.createdDate) - new Date(a.lastText.createdDate) });
//     return rArray;
//   }
// );

// export const selectCurrentContact = createSelector(
//   [selectContacts],
//   (contacts) => contacts.currentContact
// );

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectUserOrg } from 'redux/user/user.selectors';

import { toggleOrgOnlineStart } from 'redux/user/user.actions';

const IsOnline = (props) => {
  const [isOrgOnline, setIsOrgOnline] = useState(props.org.isOnline);

  const handleClick = (event) => {
    const target = event.target;
    const checked = target.checked;

    setIsOrgOnline(checked);
    props.toggleOrgOnlineStart(checked)
  };

  return (
    <div className="custom-control custom-switch custom-control-sm">
      <input type="checkbox" className="custom-control-input" id="customSwitch1" checked={isOrgOnline ? true : false} onClick={handleClick} />
      <label className="custom-control-label" htmlFor="customSwitch1">{isOrgOnline ? 'Online' : 'Offline'}</label>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  org: selectUserOrg,
});

const mapDispatchToProps = (dispatch) => ({
  toggleOrgOnlineStart: (b) =>
    dispatch(toggleOrgOnlineStart(b)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(IsOnline);

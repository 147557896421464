import { takeLatest, put, all, call } from 'redux-saga/effects';
import axios from 'axios';

import ContactActionTypes from './contact.types';

import {
  getContactsSuccess,
  getContactsFailure,
  updateContactSuccess,
  updateContactFailure,
  updateHiddenContactStart,
  updateHiddenContactFailure
} from './contact.actions';

const URL = process.env.REACT_APP_API_URL;

export function* getContacts() {
  try {
    const { data } = yield axios.get(`${URL}/contacts`)
    yield put(getContactsSuccess(data));
  } catch (error) {
    yield put(getContactsFailure(error));
  }
}

export function* onGetContactsStart() {
  yield takeLatest(ContactActionTypes.GET_CONTACTS_START, getContacts);
}

export function* updateContact({ payload: { id, name: contactName, hiddenUsers, currentUser } }) {
  const name = contactName ? contactName : null;
  try {

    if (currentUser.roleId === 1) {
      for (let huId in hiddenUsers) {
        const hu = hiddenUsers[huId];
        if (hu.changed) {
          yield axios.post(`${URL}/contacts/hidden-contact`, { userId: huId, contactId: id, value: hu.value ? 1 : 0 });
        }
      }
    }

    const { data } = yield axios.patch(`${URL}/contacts/${id}/name`, { name });

    yield put(updateContactSuccess(data));
  } catch (error) {
    yield put(updateContactFailure(error));
  }


}

export function* onUpdateContactStart() {
  yield takeLatest(ContactActionTypes.UPDATE_CONTACT_START, updateContact);
}

export function* updateHiddenContact({ payload: { userId, contactId, value } }) {
  try {
    const { data } = yield axios.post(`${URL}/contacts/hidden-contact`, { userId, contactId, value });
    yield put(updateHiddenContactStart(data));
  } catch (error) {
    yield put(updateHiddenContactFailure(error));
  }
}


export function* onUpdateHiddenContactStart() {
  yield takeLatest(ContactActionTypes.UPDATE_HIDDEN_CONTACT_START, updateHiddenContact);
}

export function* contactSagas() {
  yield all([call(onGetContactsStart), call(onUpdateContactStart), call(onUpdateHiddenContactStart)]);
}

const SessionActionTypes = {
  CLEAR_SESSION: 'CLEAR_SESSION',
  SET_SESSION: 'SET_SESSION',
  START_SET_SESSION: 'START_SET_SESSION',
  SET_SESSION_SUCCESS: 'SET_SESSION_SUCCESS',
  SET_AWS: 'SET_AWS',
  CLEAR_AWS: 'CLEAR_AWS'
};

export default SessionActionTypes;

const WidgetMessageActionTypes = {
  GET_WIDGET_CONTACT_MESSAGES_START: 'GET_WIDGET_CONTACT_MESSAGES_START',
  GET_WIDGET_CONTACT_MESSAGES_SUCCESS: 'GET_WIDGET_CONTACT_MESSAGES_SUCCESS',
  GET_WIDGET_CONTACT_MESSAGES_FAILURE: 'GET_WIDGET_CONTACT_MESSAGES_FAILURE',
  SET_CURRENT_ID: 'SET_CURRENT_ID',
  NEW_WIDGET_MESSAGE_START: 'NEW_WIDGET_MESSAGE_START',
  NEW_WIDGET_MESSAGE_SUCCESS: 'NEW_WIDGET_MESSAGE_SUCCESS',
  NEW_WIDGET_MESSAGE_FAILURE: 'NEW_WIDGET_MESSAGE_FAILURE',
  RECEIVE_MESSAGE_START: 'RECEIVE_MESSAGE_START',
  RECEIVE_MESSAGE_SUCCESS: 'RECEIVE_MESSAGE_SUCCESS',
  RECEIVE_MESSAGE_FAILURE: 'RECEIVE_MESSAGE_FAILURE',
  SET_WIDGET_READ_START: 'SET_WIDGET_READ_START',
  SET_WIDGET_READ_SUCCESS: 'SET_WIDGET_READ_SUCCESS',
  SET_WIDGET_READ_FAILURE: 'SET_WIDGET_READ_FAILURE'
};

export default WidgetMessageActionTypes;

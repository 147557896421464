import { takeLatest, put, all, call } from 'redux-saga/effects';
import axios from 'axios';

import UserActionTypes from './user.types';

import {
  signInSuccess,
  signInFailure,
  fulfillUser,
  signOutSuccess,
  fulfillTwilioToken,
  toggleOrgOnlineSuccess,
  toggleOrgOnlineFailure
} from './user.actions';

const URL = process.env.REACT_APP_API_URL;

export const LOGIN_URL = `${URL}/auth/signin`;
export const REGISTER_URL = `${URL}/auth/signup`;
export const ME_URL = `${URL}/auth/me`;
export const GENERATE_TOKEN_URL = `${URL}/auth/token/generate`;
export const SET_ORG_IS_ONLINE_URL = `${URL}/organizations/isonline`;
// export const REQUEST_PASSWORD_URL = 'api/auth/forgot-password';

export function* signIn({ payload: { email, password, organization } }) {
  try {
    const {
      data
    } = yield axios.post(LOGIN_URL, { email, password, organizationName: organization });
    yield put(signInSuccess(data));
  } catch (error) {
    console.log('ERROR', error.response.data)
    yield put(signInFailure(error));
  }
}

export function* isUserAuthenticated() {
  try {
    const { data: user } = yield axios.get(ME_URL);
    yield put(fulfillUser(user));
  } catch (err) {
    console.log(err);
    yield put(signOutSuccess());
  }
}

export function* generateTwilioToken() {
  try {
    const { data: token } = yield axios.get(GENERATE_TOKEN_URL);
    yield put(fulfillTwilioToken(token.token));
  } catch (err) {
    console.log(err);
  }
}

export function* toggleOrgOnline({ payload: status }) {
  try {
    const { data: { isOnline } } = yield axios.put(SET_ORG_IS_ONLINE_URL, { status });
    yield put(toggleOrgOnlineSuccess(isOnline));
  } catch (err) {
    yield put(toggleOrgOnlineFailure(err));
    console.log(err);
  }
}

export function* onSignInStart() {
  yield takeLatest(UserActionTypes.SIGN_IN_START, signIn);
}

export function* onCheckUserSession() {
  yield takeLatest(UserActionTypes.CHECK_USER_SESSION, isUserAuthenticated);
}

export function* onGenerateTwilioToken() {
  yield takeLatest(UserActionTypes.GENERATE_TWILIO_TOKEN, generateTwilioToken);
}

export function* signOut() {
  yield put(signOutSuccess());
}

export function* onSignOutStart() {
  yield takeLatest(UserActionTypes.SIGN_OUT_START, signOut);
}

export function* onToggleOrgOnlineStart() {
  yield takeLatest(UserActionTypes.TOGGLE_ORG_ONLINE_START, toggleOrgOnline);
}

export function* userSagas() {
  yield all([call(onSignInStart), call(onSignOutStart), call(onCheckUserSession), call(onGenerateTwilioToken), call(onToggleOrgOnlineStart)]);
}

import React from 'react';
import { useSelector } from 'react-redux';

const PersonalInfo = () => {
  const { user } = useSelector(({ auth }) => ({
    user: auth.user,
  }));

  return (
    <React.Fragment>
      <div className="nk-block-head nk-block-head-lg">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">Personal Information</h4>
            <div className="nk-block-des">
              <p>Basic info, like your name and address, that you use on Txtn.</p>
            </div>
          </div>
          <div className="nk-block-head-content align-self-start d-lg-none">
          </div>
        </div>
      </div>
      <div className="nk-block">
        <div className="nk-data data-list">
          <div className="data-head">
            <h6 className="overline-title">Basics</h6>
          </div>
          <div className="data-item">
            <div className="data-col"><span className="data-label">Oraganization Name</span><span className="data-value text-soft">{user.organization.name}</span></div>
            <div className="data-col data-col-end"><span className="data-more disable"><em className="icon ni ni-lock-alt"></em></span></div>
          </div>
          <div className="data-item">
            <div className="data-col"><span className="data-label">Organization Phone Number</span><span className="data-value text-soft">{user.organization.phoneNumber}</span></div>
            <div className="data-col data-col-end"><span className="data-more disable"><em className="icon ni ni-lock-alt"></em></span></div>
          </div>
          <div className="data-item">
            <div className="data-col"><span className="data-label">Personal Phone Number</span><span className="data-value text-soft">{user.phoneNumber}</span></div>
            <div className="data-col data-col-end"><span className="data-more disable"><em className="icon ni ni-lock-alt"></em></span></div>
          </div>
          <div className="data-item">
            <div className="data-col"><span className="data-label">Email</span><span className="data-value text-soft">{user.email}</span></div>
            <div className="data-col data-col-end"><span className="data-more disable"><em className="icon ni ni-lock-alt"></em></span></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PersonalInfo;

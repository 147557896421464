import ContactActionTypes from './contact.types';

export const getContactsStart = () => ({
  type: ContactActionTypes.GET_CONTACTS_START,
});

export const getContactsSuccess = (contacts) => ({
  type: ContactActionTypes.GET_CONTACTS_SUCCESS,
  payload: contacts,
});

export const getContactsFailure = (error) => ({
  type: ContactActionTypes.GET_CONTACTS_FAILURE,
  payload: error,
});

export const setCurrentContact = (number) => ({
  type: ContactActionTypes.SET_CURRENT_CONTACT,
  payload: number,
});

export const updateContactStart = (contact) => ({
  type: ContactActionTypes.UPDATE_CONTACT_START,
  payload: contact
});

export const updateContactSuccess = (contact) => ({
  type: ContactActionTypes.UPDATE_CONTACT_SUCCESS,
  payload: contact,
});

export const updateContactFailure = (error) => ({
  type: ContactActionTypes.UPDATE_CONTACT_FAILURE,
  payload: error,
});

export const updateHiddenContactStart = (userId, contactId, value) => ({
  type: ContactActionTypes.UPDATE_HIDDEN_CONTACT_START,
  payload: { userId, contactId, value }
});

export const updateHiddenContactSuccess = (employee) => ({
  type: ContactActionTypes.UPDATE_HIDDEN_CONTACT_SUCCESS,
  payload: employee
});

export const updateHiddenContactFailure = (error) => ({
  type: ContactActionTypes.UPDATE_HIDDEN_CONTACT_FAILURE,
  payload: error,
});

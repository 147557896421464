import React from 'react'

import { useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.scss';

import Login from './pages/login/login.component';
import Register from './pages/register/register.component'
import Callback from './pages/callback/Callback'

import Logout from './pages/logout/logout.component';
import Routes from './Routes';

const App = (props) => {
  const { isAuthorized } = useSelector(({ session }) => ({
    isAuthorized: session.credentials.accessToken != null,
  }));

  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/logout" component={Logout} />
        <Route
          exact
          path="/login"
          render={() =>
            isAuthorized ? <Redirect to="/" /> : <Login />
          }
        />
        <Route
          exact
          path="/register"
          render={() =>
            isAuthorized ? <Redirect to="/" /> : <Register />
          }
        />
        <PrivateRoute path={["/chat", "/dashboard", "/contacts", "/phone", "/widget-chat", "/account-settings"]}>
          <Routes />
        </PrivateRoute>
        <Route exact path="/callback" component={Callback}/>
        <Redirect from="/" to="/dashboard" />
      </Switch>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover />
    </React.Fragment>
  );
};

export default App;

import MessageActionTypes from './messages.types';

const INITIAL_STATE = {
  items: [],
  itemsByNumber: {},
  error: null,
};

const messageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MessageActionTypes.GET_TEXTS_START:
    case MessageActionTypes.GET_CONTACTS_TEXTS_START:
      return {
        ...state,
        isFetching: true,
      };
    case MessageActionTypes.GET_TEXTS_SUCCESS: {
      const pl = action.payload;
      const itemsSorted = pl.slice().sort((a, b) => {
        var c = new Date(a.createdDate);
        var d = new Date(b.createdDate);
        return c - d;
      });
      const itemsByNumber = itemsSorted.reduce(
        (result, item) => ({
          ...result,
          [item.contact.phoneNumber]: [...(result[item.contact.phoneNumber] || []), item],
        }),
        {}
      );
      return {
        ...state,
        items: pl,
        itemsByNumber,
        isFetching: false,
        error: null,
      };
    }
    case MessageActionTypes.GET_CONTACTS_TEXTS_SUCCESS: {
      const pl = action.payload;
      const itemsSorted = pl.messages.slice().sort((a, b) => {
        var c = new Date(a.createdDate);
        var d = new Date(b.createdDate);
        return c - d;
      });
      // const itemsByNumber = itemsSorted.reduce(
      //   (result, item) => ({
      //     ...result,
      //     [item.contact.phoneNumber]: [...(result[item.contact.phoneNumber] || []), item],
      //   }),
      //   {}
      // );

      let items = state.items;
      if (!items) items = [...pl.messages];
      else items.push(...pl.messages)

      return {
        ...state,
        items,
        itemsByNumber: {
          ...state.itemsByNumber,
          [pl.phoneNumber]: itemsSorted
        },
        isFetching: false,
        error: null,
      };
    }
    case MessageActionTypes.NEW_TEXT_WEBSOCKET: {
      const pl = action.payload;
      if (pl.currentUserId === pl.userId) return state;
      let items = [...state.items];
      items.push(pl);
      let itemNumber = state.itemsByNumber[pl.contact.phoneNumber];
      if (!itemNumber) itemNumber = [];
      itemNumber.push(pl);
      return {
        ...state,
        items,
        itemsByNumber: {
          ...state.itemsByNumber,
          [pl.contact.phoneNumber]: [...itemNumber],
        },
      };
    }
    case MessageActionTypes.NEW_TEXT_SUCCESS: {
      const pl = action.payload;
      let items = [...state.items];
      items.push(pl);

      let itemNumber = state.itemsByNumber[pl.contact.phoneNumber];
      if (!itemNumber) itemNumber = [];
      itemNumber.push(pl);
      return {
        ...state,
        items,
        itemsByNumber: {
          ...state.itemsByNumber,
          [pl.contact.phoneNumber]: [...itemNumber],
        },
      };
    }
    case MessageActionTypes.GET_TEXTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case MessageActionTypes.SET_CURRENT_NUMBER:
      return {
        ...state,
        currentNumber: action.payload,
      };
    default:
      return state;
  }
};

export default messageReducer;

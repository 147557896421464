import React, { Component } from 'react';
import { connect } from 'react-redux';
import Pusher from 'pusher-js';
import { store } from './redux/store';
import { Switch, Redirect } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import Notification from "react-web-notification";
import setupTwilioDevice from './utils/setupTwilioDevice';

import Header from './components/header/header.component';
import SideBar from './components/sidebar/sidebar.component';
import SideBarMobile from './components/sidebar-mobile/sidebar-mobile.component';
import PrivateRoute from './components/PrivateRoute';

import Dashboard from './pages/dashboard/dashboard.component';
import Chat from './containers/chat/chat.component';
import WidgetChat from './pages/addons/widget-chat/WidgetChat';
import ContactsWidget from './containers/contacts-widget/contacts-widget.container';
import PhoneWidget from './containers/phone-widget/phone-widget.container';
import AccountSettings from './pages/AccountSettings/AccountSettingsPage';

// Selectors
import { selectTwilioToken, selectUser } from './redux/user/user.selectors';
import { selectCallsAddon, selectWidgetAddon, selectTextsAddon } from './redux/user/user.selectors';
import { selectBrowserNotifications, selectSoundNotifications } from './redux/ui/ui.selectors';

// Actions
import { newTextWebsocket } from './redux/messages/messages.actions';
import { checkUserSession, generateTwilioToken } from './redux/user/user.actions';
import { getEmployeesStart } from './redux/employee/employee.actions';
import { getContactsStart, updateContactSuccess } from './redux/contact/contact.actions';
import { getWidgetContactsStart, updateWidgetContactSuccess } from './redux/widget-contact/widget-contact.actions';
import { receiveWidgetMessageStart } from './redux/widget-messages/widget-messages.actions';

import widgetMessageMP3 from './assets/media/click.mp3';

// Enable pusher logging - don't include this in production
if (process.env.NODE_ENV !== 'production') {
  Pusher.logToConsole = true;
}

class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      twilioStatus: undefined,
      device: undefined,
      connectionFunction: undefined,
      rejectFunction: undefined,
      sendDigits: undefined,
      notificationTitle: "",
      notificationBody: ""
    }
    this.hideNotification = this.hideNotification.bind(this);
    this.showNotification = this.showNotification.bind(this);
    this.setupTwilioDevice = setupTwilioDevice.bind(this);
  }

  componentDidMount() {
    this.props.checkUserSession();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectUser != null && prevProps.selectUser !== this.props.selectUser) {
      this.props.getEmployeesStart();
      this.props.getContactsStart();
      this.props.generateTwilioToken();

      // Pusher Connection
      const org = store.getState().auth.user.organization.id;
      const { session: { credentials: {accessToken} } } = store.getState();

      this.channel = undefined;
      if ((this.props.selectTextsAddon || this.props.selectWidgetAddon)) {
        var pusher = new Pusher(process.env.REACT_APP_PUSHER_KEY, {
          cluster: 'us2',
          forceTLS: true,
          authEndpoint: `${process.env.REACT_APP_API_URL}/auth/pusher`,
          auth: {
            headers: {
              'Authorization': `Bearer ${accessToken}`
            }
          }
        });
        var channel = pusher.subscribe(`presence-${org.toString()}`);
        this.channel = channel;
      }

      // If the Texts Addons is enabled then bind the socket functions
      if (this.channel && this.props.selectTextsAddon) {
        this.channel.bind('msgToClient', (md) => {
          const userId = store.getState().auth.user.id;
          md.currentUserId = userId;
          if (userId !== md.userId) {
            this.showNotification("New Text Message", `From: ${md.contact.phoneNumber}`);
            this.props.newText(md);
          }
        });
        this.channel.bind('readText', (md) => {
          const userId = store.getState().auth.user.id;
          md.currentUserId = userId;
          this.props.updateContactSuccess(md);
        });
      }
      
      // If the Widget Addons is enabled then get the contacts and bind the socket function
      if (this.channel && this.props.selectWidgetAddon) {
        this.props.getWidgetContactsStart();

        this.channel.bind('newWidgetMessage', ({ message, contact }) => {
          message.contact = contact;
          this.props.receiveWidgetMessage(message);
          this.showNotification("New Browser Message", message.input);
          if(this.props.selectSoundNotifications){
            const audio = new Audio(widgetMessageMP3);
            audio.play()
          }
        });
        this.channel.bind('readWidgetMessage', (md) => {
          const userId = store.getState().auth.user.id;
          md.currentUserId = userId;
          console.log(md);
          this.props.updateWidgetContactSuccess(md);
        });
      }
    }

    if (prevProps.twilioToken !== this.props.twilioToken) {
      const twilioToken = this.props.twilioToken;
      const callsAddon = this.props.selectCallsAddon
      if (twilioToken && callsAddon) {
        // Setup Twilio.Device
        const device = this.setupTwilioDevice(this, twilioToken, this.showNotification.bind(this));
        this.phoneDevice = device;
      }
    }

    if ("Notification" in window) {
      window.Notification.requestPermission();
    }

    this.phoneDevice = undefined;
  }

  componentWillUnmount() {
    if (this.channel && this.props.selectTextsAddon) {
      this.channel.disconnect();
    }
  }

  showNotification(title, message) {
    if(this.props.selectBrowserNotifications){
      this.setState({notificationTitle: title, notificationBody: message});
    }
  }

  hideNotification() {
    this.setState({notificationTitle: '', notificationBody: ''})
  }

  render() {
    const {notificationTitle, notificationBody} = this.state;
    return (
      <div className="nk-app-root">
        <SideBar />
        <div className="nk-main">
          <div className="nk-wrap" style={{paddingTop: "65px"}}>
            {/* ROUTE */}
            <Header />
            <SideBarMobile />
            <Switch>
              <PrivateRoute exact path="/chat">
                <Chat />
              </PrivateRoute>
              <PrivateRoute exact path="/dashboard">
                <Dashboard />
              </PrivateRoute>
              <PrivateRoute exact path="/contacts">
                <ContactsWidget />
              </PrivateRoute>
              <PrivateRoute exact path="/phone">
                <PhoneWidget
                  status={this.state.twilioStatus} 
                  device={this.state.device} 
                  connectionFunction={this.state.connectionFunction} 
                  rejectFunction={this.state.rejectFunction} 
                  sendDigits={this.state.sendDigits} />
              </PrivateRoute>
              <PrivateRoute exact path="/widget-chat">
                <WidgetChat />
              </PrivateRoute>
              <PrivateRoute path="/account-settings">
                <AccountSettings />
              </PrivateRoute>
              <Redirect from="/" to="/dashboard" />
            </Switch>
          </div>
        </div>
        {notificationTitle ? (
        <Notification
          title={notificationTitle}
          options={{
            body: notificationBody
          }}
          onClose={this.hideNotification}
          onClick={() => window.focus()}
        />
      ) : null}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  selectUser: selectUser,
  twilioToken: selectTwilioToken,
  selectCallsAddon: selectCallsAddon,
  selectWidgetAddon: selectWidgetAddon,
  selectTextsAddon: selectTextsAddon,
  selectBrowserNotifications: selectBrowserNotifications,
  selectSoundNotifications: selectSoundNotifications
});

const mapDispatchToProps = (dispatch) => ({
  getEmployeesStart: () => dispatch(getEmployeesStart()),
  getContactsStart: () => dispatch(getContactsStart()),
  getWidgetContactsStart: () => dispatch(getWidgetContactsStart()),
  newText: (m) => dispatch(newTextWebsocket(m)),
  checkUserSession: () => dispatch(checkUserSession()),
  updateContactSuccess: (c) => dispatch(updateContactSuccess(c)),
  generateTwilioToken: () => dispatch(generateTwilioToken()),
  receiveWidgetMessage: (m) => dispatch(receiveWidgetMessageStart(m)),
  updateWidgetContactSuccess: (c) => dispatch(updateWidgetContactSuccess(c))
});

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectBrowserNotifications, selectSoundNotifications } from '../../redux/ui/ui.selectors';
import { setBrowserNotifications, setSoundNotifications } from '../../redux/ui/ui.actions';

const Notifications = (props) => {
  let supported = false;
  let granted = false;

  if (('Notification' in window) && window.Notification) {
    supported = true;
    if (window.Notification.permission === 'granted') {
      granted = true;
    }
  } else {
    props.setBrowserNotifications(false)
  }

  const handleBrowserClick = (event) => {
    const target = event.target;
    let checked = target.checked;

    if (!supported) {
      alert("Notifications are not supported by your browser.");
      return;
    }

    if (supported && !granted) {
      Notification.requestPermission();
    }

    if (Notification.permission === "denied" && checked === true) {
      alert("Notifications blocked. Please enable them in your browser.");
      checked = false;
    }
    props.setBrowserNotifications(checked)
  };

  const handleSoundClick = (event) => {
    const target = event.target;
    const checked = target.checked;
    props.setSoundNotifications(checked)
  };

  return (
    <React.Fragment>
      <div className="nk-block-head nk-block-head-lg">
        <div className="nk-block-between">
          <div className="nk-block-head-content">
            <h4 className="nk-block-title">Notification Settings</h4>
            <div className="nk-block-des">
              <p>You will get only notification for what you have enabled.</p>
            </div>
          </div>
          <div className="nk-block-head-content align-self-start d-lg-none">
          </div>
        </div>
      </div>
      <div className="nk-block-head nk-block-head-sm">
        <div className="nk-block-head-content">
          <h6>General</h6>
        </div>
      </div>
      <div className="nk-block-content">
        <div className="gy-3">
          <div className="g-item">
            <div className="custom-control custom-switch">
              <input type="checkbox" v="custom-control-input" id="unusual-activity" checked={props.selectBrowserNotifications ? true : false} onClick={handleBrowserClick} />
              <label className="custom-control-label" for="unusual-activity">Browser notifications for new messages</label>
            </div>
          </div>
          <div className="g-item">
            <div className="custom-control custom-switch">
              <input type="checkbox" className="custom-control-input" id="new-browser" checked={props.selectSoundNotifications ? true : false} onClick={handleSoundClick} />
              <label className="custom-control-label" for="new-browser">Sound notifications for new messages</label>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
}

const mapStateToProps = createStructuredSelector({
  selectBrowserNotifications: selectBrowserNotifications,
  selectSoundNotifications: selectSoundNotifications
});

const mapDispatchToProps = (dispatch) => ({
  setBrowserNotifications: (b) => dispatch(setBrowserNotifications(b)),
  setSoundNotifications: (b) => dispatch(setSoundNotifications(b)),
});

export default connect(
  mapStateToProps, mapDispatchToProps
)(Notifications);
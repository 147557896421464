import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { clearSession } from '../../redux/session/session.actions';
// import cognitoUtils from '../../utils/cognitoUtils';

class Logout extends Component {
  componentDidMount() {
    // cognitoUtils.signOutCognitoSession();
    this.props.clearSession();
  }

  render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? null : <Redirect to="/" />;
  }
}

const mapDispatchToProps = (dispatch) => ({
  clearSession: () => dispatch(clearSession()),
});

export default connect(
  ({ session }) => ({ hasAuthToken: session.credentials.accessToken != null }),
  mapDispatchToProps
)(Logout);

import { takeLatest, put, all, call } from 'redux-saga/effects';
import axios from 'axios';

import EmployeeActionTypes from './employee.types';

import { getEmployeesSuccess, getEmployeesFailure } from './employee.actions';

const URL = process.env.REACT_APP_API_URL;
export const EMPLOYEE_URL = `${URL}/auth/users`;

export function* getEmployees() {
  try {
    const { data } = yield axios.get(EMPLOYEE_URL);
    yield put(getEmployeesSuccess(data));
  } catch (error) {
    yield put(getEmployeesFailure(error));
  }
}

export function* onGetEmployeesStart() {
  yield takeLatest(EmployeeActionTypes.GET_EMPLOYEES_START, getEmployees);
}

export function* employeeSagas() {
  yield all([call(onGetEmployeesStart)]);
}

import React from 'react';

const AwsLoginButton = (props) => {
  return (
    <div className="form-group">
      <a href={props.href} className="btn btn-lg btn-primary btn-block">Sign into {props.org}</a>
    </div>
  );
};


export default AwsLoginButton;

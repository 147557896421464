import SessionActionTypes from './session.types';

export const clearSession = () => ({
  type: SessionActionTypes.CLEAR_SESSION
})

export const initSessionFromCallbackURI = (callbackHref) => ({
  type: SessionActionTypes.START_SET_SESSION,
  payload: callbackHref,
})

export const setSessionSuccess = (session) => ({
  type: SessionActionTypes.SET_SESSION_SUCCESS,
  payload: session,
})

export const setAWS = (aws) => ({
  type: SessionActionTypes.SET_AWS,
  payload: aws,
})

export const clearAWS = () => ({
  type: SessionActionTypes.CLEAR_AWS
})
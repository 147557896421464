import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import axios from 'axios';

// Components
import FormInput from '../../components/form-input/form-input.component';

// Selectors
import { selectError } from '../../redux/user/user.selectors';
import { selectCognito } from '../../redux/session/session.selectors';

// Actions
import { signInStart } from '../../redux/user/user.actions';
import { setAWS } from '../../redux/session/session.actions';

const LoginForm = (props) => {
  const currentError = props.currentError;
  const [userCredentials, setCredentials] = useState({
    organization: props.sd ? props.sd : '',
  });

  const [error, setError] = useState(currentError ? (typeof currentError.response.data.message === Array ? currentError.response.data.message.join(', ') : currentError.response.data.message) : '');

  React.useEffect(() => {
    setError(currentError ? (typeof currentError.response.data.message === Array ? currentError.response.data.message.join(', ') : currentError.response.data.message) : '')
  }, [currentError]);

  const { organization } = userCredentials;

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!organization.trim()) {
      setError('Organization cannot be blank')
    }
    const URL = process.env.REACT_APP_API_URL;
    const ORG_CLIENTID_URL = `${URL}/organizations/${organization}`;
    axios.get(ORG_CLIENTID_URL).then(x => {
      if (x.status === 200) {
        props.setAWS({ cognitoClientId: x.data.cognitoClientId, cognitoUserPool: x.data.cognitoUserPool, org: organization });
      }
    }).catch(error => {
      if (error.response) {
        switch (error.response.status) {
          case 404:
            setError('Please enter a valid organization name.')
            break;
          default:
            setError(error.response.data.mssage)
            break;
        }
        console.log(error.response.data);
      } else {
        setError('Unknown error. Please try again')
      }
    })
    return;
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setCredentials({ ...userCredentials, [name]: value });
  };

  console.log(props.selectCognito)
  return (
    <form onSubmit={handleSubmit}>
      <FormInput
        placeholder="Enter your organization"
        type="organization"
        name="organization"
        label="Organization"
        autoComplete="organization"
        value={organization}
        onChange={handleChange}
      />
      <div className="form-group">
        <span style={{ color: "red" }}>{error}</span>
      </div>
      <div className="form-group">
        <button className="btn btn-lg btn-primary btn-block">Continue</button>
      </div>
    </form >
  );
};

const mapStateToProps = createStructuredSelector({
  currentError: selectError,
  selectCognito: selectCognito
});

const mapDispatchToProps = (dispatch) => ({
  signInStart: (email, password, organization) => dispatch(signInStart({ email, password, organization })),
  setAWS: (aws) => dispatch(setAWS(aws))
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);

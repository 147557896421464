const ContactActionTypes = {
  GET_CONTACTS_START: 'GET_CONTACTS_START',
  GET_CONTACTS_SUCCESS: 'GET_CONTACTS_SUCCESS',
  GET_CONTACTS_FAILURE: 'GET_CONTACTS_FAILURE',
  SET_CURRENT_CONTACT: 'SET_CURRENT_CONTACT',
  UPDATE_CONTACT_START: 'UPDATE_CONTACT_START',
  UPDATE_CONTACT_SUCCESS: 'UPDATE_CONTACT_SUCCESS',
  UPDATE_CONTACT_FAILURE: 'UPDATE_CONTACT_FAILURE',
  UPDATE_HIDDEN_CONTACT_START: 'UPDATE_HIDDEN_CONTACT_START',
  UPDATE_HIDDEN_CONTACT_SUCCESS: 'UPDATE_HIDDEN_CONTACT_SUCCESS',
  UPDATE_HIDDEN_CONTACT_FAILURE: 'UPDATE_HIDDEN_CONTACT_FAILURE'
};

export default ContactActionTypes;

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { useHistory, useLocation } from 'react-router-dom'
import LoginForm from '../../containers/login-form/login-form.component';
import logo from '../../assets/media/logo-trans.png';
import AwsLoginButton from '../../components/aws-login-button/aws-login-button.component';

import { selectCognito } from '../../redux/session/session.selectors';
import { clearAWS } from '../../redux/session/session.actions';

const Login = (props) => {
  const [sd, setSd] = useState(null);
  const location = useLocation();
  const history = useHistory();
  const { cognitoClientId, org: organization } = props.selectCognito;

  // If user access a subdomain reroute to main domain with query string
  var subdomain = window.location.host.split('.')[1] ? ((window.location.host.split('.')[0] !== 'www' && window.location.host.split('.')[0] !== 'app') ? window.location.host.split('.')[0] : false) : false;
  if (subdomain) {
    let url = `https://app.mytxtn.com/login?sd=${subdomain}`;
    if (process.env.NODE_ENV === 'development') {
      url = `http://${window.location.hostname.substring(window.location.hostname.indexOf(".") + 1)}:${window.location.port}/login?sd=${subdomain}`;
    }
    window.location.assign(url);
  }
  useEffect(() => {
    // Clear session on page load
    props.clearAWS();

    // If we were redirected from a subdomain remove it in the URL and set the state
    const queryParams = new URLSearchParams(location.search)
    if (queryParams.has('sd')) {
      setSd(queryParams.get('sd'))
      queryParams.delete('sd')
      history.replace({
        search: queryParams.toString(),
      })
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="nk-app-root">
      <div className="nk-main ">
        <div className="nk-wrap">
          <div className="nk-content ">
            <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
              <div className="brand-logo pb-4 text-center">
                <a href="html/index.html" className="logo-link">
                  <img className="logo-light logo-img logo-img-lg" src={logo} alt="logo" />
                  <img className="logo-dark logo-img logo-img-lg" src={logo} alt="logo-dark" />
                </a>
              </div>
              <div className="card card-bordered">
                <div className="card-inner card-inner-lg">
                  <div className="nk-block-head">
                    <div className="nk-block-head-content">
                      <h4 className="nk-block-title">Sign-In</h4>
                      <div className="nk-block-des">
                        <p>Sign in to access the TXTN dashboard.</p>
                      </div>
                    </div>
                  </div>
                  {
                    cognitoClientId ? <AwsLoginButton org={organization} href={`https://${organization}.auth.us-east-2.amazoncognito.com/login?response_type=code&client_id=${cognitoClientId}&redirect_uri=${process.env.NODE_ENV === 'development' ? 'http://localhost:3000/callback' : 'https://app.mytxtn.com/callback'}`} /> : <LoginForm sd={sd} />
                  }
                  {
                    cognitoClientId ? <div className="form-note-s2 text-center">
                      <button onClick={() => props.clearAWS()} type="button" className="btn btn-link">Need to sign in to a different organization?</button>
                    </div> : null
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  selectCognito: selectCognito
});

const mapDispatchToProps = (dispatch) => ({
  clearAWS: () => dispatch(clearAWS())
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);

import { createSelector } from 'reselect';

const selectAuth = (state) => state.auth;

export const selectUser = createSelector([selectAuth], (user) => user.user);

export const selectError = createSelector([selectAuth], (auth) => auth.error);

export const selectUserEmail = createSelector(
  [selectUser],
  (user) => {
    if (!user) return "";
    return user.email
  }
);

export const selectUserOrg = createSelector(
  [selectUser],
  (user) => {
    if (!user) return {};
    return user.organization
  }
);

export const selectUserOrgsAddons = createSelector(
  [selectUserOrg],
  (org) => {
    if (!org || !org.addons) return [];
    return org.addons
  }
);

export const selectCallsAddon = createSelector(
  [selectUserOrgsAddons],
  (addons) => {
    if (!addons) return null;

    const filteredAddon = addons.filter(x => x.name === "calls")
    const callsAddons = filteredAddon[0];

    return callsAddons || null;
  }
);

export const selectWidgetAddon = createSelector(
  [selectUserOrgsAddons],
  (addons) => {
    if (!addons) return null;

    const filteredAddon = addons.filter(x => x.name === "widget")
    const widgetAddons = filteredAddon[0];

    return widgetAddons || null;
  }
);

export const selectTextsAddon = createSelector(
  [selectUserOrgsAddons],
  (addons) => {
    if (!addons) return null;

    const filteredAddon = addons.filter(x => x.name === "texts")
    const widgetAddons = filteredAddon[0];

    return widgetAddons || null;
  }
);

export const selectContactsAddon = createSelector(
  [selectUserOrgsAddons],
  (addons) => {
    if (!addons) return null;

    const filteredAddon = addons.filter(x => x.name === "contacts")
    const widgetAddons = filteredAddon[0];

    return widgetAddons || null;
  }
);

export const selectTwilioToken = createSelector([selectAuth], (user) => user.twilioToken);
import UIActionTypes from './ui.types';

const INITIAL_STATE = {
  error: null,
  browserNotifications: true,
  soundNotifications: true,
  enterToCall: false
};

const UIReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UIActionTypes.SET_SHOW_CHAT_BODY:
      return {
        ...state,
        showChat: action.payload,
      };
    case UIActionTypes.SET_SHOW_WIDGET_CHAT_BODY:
      return {
        ...state,
        showWidgetChat: action.payload,
      };
    case UIActionTypes.SET_BROWSER_NOTIFICATIONS:
      return {
        ...state,
        browserNotifications: action.payload,
      };
    case UIActionTypes.SET_SOUND_NOTIFICATIONS:
      return {
        ...state,
        soundNotifications: action.payload,
      };
    case UIActionTypes.SET_ENTER_TO_CALL:
      return {
        ...state,
        enterToCall: action.payload,
      };
    default:
      return state;
  }
};

export default UIReducer;

const setupAxios = (axios, store) => {
  axios.interceptors.request.use(
    (config) => {
      const {
        session,
      } = store.getState();

      const accessToken = session.credentials ? session.credentials.accessToken : null;
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    },
    (err) => Promise.reject(err)
  );
};

export default setupAxios;

import WidgetContactActionTypes from './widget-contact.types';

export const getWidgetContactsStart = () => ({
  type: WidgetContactActionTypes.GET_WIDGET_CONTACTS_START,
});

export const getWidgetContactsSuccess = (contacts) => ({
  type: WidgetContactActionTypes.GET_WIDGET_CONTACTS_SUCCESS,
  payload: contacts,
});

export const getWidgetContactsFailure = (error) => ({
  type: WidgetContactActionTypes.GET_WIDGET_CONTACTS_FAILURE,
  payload: error,
});

export const setCurrentWidgetContact = (number) => ({
  type: WidgetContactActionTypes.SET_CURRENT_WIDGET_CONTACT,
  payload: number,
});

export const updateWidgetContactStart = (contact) => ({
  type: WidgetContactActionTypes.UPDATE_WIDGET_CONTACT_START,
  payload: contact
});

export const updateWidgetContactSuccess = (contact) => ({
  type: WidgetContactActionTypes.UPDATE_WIDGET_CONTACT_SUCCESS,
  payload: contact,
});

export const updateWidgetContactFailure = (error) => ({
  type: WidgetContactActionTypes.UPDATE_WIDGET_CONTACT_FAILURE,
  payload: error,
});
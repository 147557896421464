import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectIsMessagesFetching } from '../../redux/messages/messages.selectors';
import WithSpinner from '../../components/with-spinner/with-spinner.component';
import MessageConversation from './message-conversation.component';

const mapStateToProps = createStructuredSelector({
  isLoading: (state) => selectIsMessagesFetching(state),
});

const MessageConversationContainer = connect(mapStateToProps)(WithSpinner(MessageConversation));

export default MessageConversationContainer;

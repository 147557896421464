import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Redirect } from 'react-router-dom';

import ContactsList from '../contacts-list/contacts-list.component';
import { selectContactsAddon } from '../../redux/user/user.selectors';

const ContactWidget = (props) => {
  const [searchText, setSearchText] = useState('');

  if (!props.selectContactsAddon) {
    return <Redirect
      to={{
        pathname: '/',
        state: { from: '/contacts' },
      }}
    />
  }

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchText(value);
  };

  return (
    <div className="nk-content ">
      <div className="container-fluid">
        <div className="nk-content-inner">
          <div className="nk-content-body">
            <div className="nk-block-head nk-block-head-sm">
              <div className="nk-block-between">
                <div className="nk-block-head-content">
                  <h3 className="nk-block-title page-title">Contacts List</h3>
                  <div className="nk-block-des text-soft">
                  </div>
                </div>
                <div className="nk-block-head-content">
                  <div className="toggle-wrap nk-block-tools-toggle">
                    <button className="btn btn-icon btn-trigger toggle-expand mr-n1" data-target="pageMenu"><em className="icon ni ni-menu-alt-r"></em></button>
                    <div className="toggle-expand-content" data-content="pageMenu">
                      <ul className="nk-block-tools g-3">
                        {/* <li><a href="#" className="btn btn-white btn-outline-light"><em className="icon ni ni-download-cloud"></em><span>Export</span></a></li> */}
                        {/* <li className="nk-block-tools-opt">
                          <div className="drodown">
                            <a href="#" className="dropdown-toggle btn btn-icon btn-primary" data-toggle="dropdown"><em className="icon ni ni-plus"></em></a>
                            <div className="dropdown-menu dropdown-menu-right">
                              <ul className="link-list-opt no-bdr">
                                <li><a href="#"><span>Add User</span></a></li>
                              </ul>
                            </div>
                          </div>
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="nk-block">
              <div className="card card-bordered card-stretch">
                <div className="card-inner-group">
                  <div className="card-inner position-relative card-tools-toggle">
                    <div className="card-title-group">
                      <div className="card-tools">
                      </div>
                      <div className="card-tools mr-n1">
                        <ul className="btn-toolbar gx-1">
                          <li>
                            <button className="btn btn-icon search-toggle toggle-search" data-target="search"><em className="icon ni ni-search"></em></button>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="card-search search-wrap" data-search="search">
                      <div className="card-body">
                        <div className="search-content">
                          <button className="search-back btn btn-icon toggle-search" data-target="search"><em className="icon ni ni-arrow-left"></em></button>
                          <input type="text" className="form-control border-transparent form-focus-none" placeholder="Search by user or email" onChange={handleSearchChange} />
                          <button className="search-submit btn btn-icon"><em className="icon ni ni-search"></em></button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-inner p-0">
                    <div className="nk-tb-list nk-tb-ulist is-compact">
                      <div className="nk-tb-item nk-tb-head">
                        <div className="nk-tb-col"><span className="sub-text">Name</span></div>
                        <div className="nk-tb-col tb-col-md"><span className="sub-text">Role</span></div>
                        <div className="nk-tb-col tb-col-md"><span className="sub-text">Phone</span></div>
                        <div className="nk-tb-col tb-col-lg"><span className="sub-text">Last Text</span></div>
                        <div className="nk-tb-col"><span className="sub-text">Status</span></div>
                        <div className="nk-tb-col nk-tb-col-tools text-right">
                        </div>
                      </div>
                      <ContactsList searchText={searchText} />
                    </div>
                  </div>
                  {/* <div className="card-inner">
                    <ul className="pagination justify-content-center justify-content-md-start">
                      <li className="page-item"><a className="page-link" href="#">Prev</a></li>
                      <li className="page-item"><a className="page-link" href="#">1</a></li>
                      <li className="page-item"><a className="page-link" href="#">2</a></li>
                      <li className="page-item"><span className="page-link"><em className="icon ni ni-more-h"></em></span></li>
                      <li className="page-item"><a className="page-link" href="#">6</a></li>
                      <li className="page-item"><a className="page-link" href="#">7</a></li>
                      <li className="page-item"><a className="page-link" href="#">Next</a></li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  selectContactsAddon: selectContactsAddon
});

export default connect(
  mapStateToProps
)(ContactWidget);
import { Device } from 'twilio-client';

const setupTwilioDevice = (thisObj, twilioToken, notificationFunction) => {
  let device = new Device(twilioToken, {
    codecPreferences: ["opus", "pcmu"],
    fakeLocalDTMF: true,
    enableRingingState: true,
    closeProtection: true
  });

  device.on("ready", (device) => {
    console.log("Twilio.Device Ready!");
    thisObj.setState({ twilioStatus: 'ready', device, connectionFunction: undefined });
  });

  device.on("error", (error) => {
    console.log("Twilio.Device Error: " + error.message);
    thisObj.setState({ twilioStatus: 'error', connectionFunction: undefined })
  });

  device.on("connect", (conn) => {
    console.log("Successfully established call!", conn.message);
    thisObj.setState({ twilioStatus: 'connect' })

    function sd(d) {
      conn.sendDigits(d);
    }

    thisObj.setState({ sendDigits: sd })
    // If phoneNumber is part of the connection, this is a call from a
    // support agent to a customer's phone
    if ("phoneNumber" in conn.message) {
      console.log(`In call with ${conn.message.phoneNumber}`);
    } else {
      console.log(`In call support`);
    }
  });

  device.on("disconnect", (conn) => {
    console.log("Twilio.Device Disconnect - READY!", conn);
    thisObj.setState({ twilioStatus: 'ready', connectionFunction: undefined })
  });

  device.on("incoming", (conn) => {
    console.log(conn)
    console.log("Incoming support call");

    notificationFunction("Incoming Call!" ,`Call from ${conn.parameters.From}`);
    // Set a callback to be executed when the connection is accepted
    // conn.accept(function () {
    //   console.log("In call with customer");
    // });

    // Set a callback on the answer button and enable it
    function cf() {
      thisObj.setState({ twilioStatus: 'connect' })
      conn.accept();
    }

    function rf() {
      thisObj.setState({ twilioStatus: 'ready' })
      conn.reject();
    }

    thisObj.setState({ twilioStatus: 'incoming', connectionFunction: cf, rejectFunction: rf })
  });

  return device;
};

export default setupTwilioDevice;


import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';

import { selectIsMessagesFetching } from '../../redux/messages/messages.selectors';
import WithSpinner from '../../components/with-spinner/with-spinner.component';
import PhoneWidget from './phone-widget.component';

const mapStateToProps = createStructuredSelector({
  isLoading: (state) => selectIsMessagesFetching(state),
});

const PhoneWidgetContainer = compose(
  connect(mapStateToProps),
  WithSpinner
)(PhoneWidget);

export default PhoneWidgetContainer;

import WidgetContactActionTypes from './widget-contact.types';
import WidgetMessageActionTypes from '../widget-messages/widget-messages.types';

const INITIAL_STATE = {
  error: null
};

const widgetContactReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WidgetContactActionTypes.GET_WIDGET_CONTACTS_START:
      return {
        ...state,
        isFetching: true,
      };
    case WidgetContactActionTypes.GET_WIDGET_CONTACTS_SUCCESS: {
      const pl = action.payload;
      const itemsById = pl.reduce(
        (result, item) => ({
          ...result,
          [item['id']]: item,
        }),
        {}
      );
      const itemsByUUID = pl.reduce(
        (result, item) => ({
          ...result,
          [item['uuid']]: item,
        }),
        {}
      );
      return {
        ...state,
        items: pl,
        itemsById,
        itemsByUUID,
        isFetching: false,
        error: null,
      };
    }
    case WidgetMessageActionTypes.RECEIVE_MESSAGE_START: {
      const pl = action.payload;
      let itemId = state.itemsById[pl.contact.id];

      const lt = { ...pl };
      delete lt.contact;

      if (!itemId) {
        return {
          ...state,
          itemsById: {
            ...state.itemsById,
            [pl.contact.id]:
            {
              ...pl.contact,
              lastText: lt
            },
          }
        }
      } else {
        return {
          ...state,
          itemsById: {
            ...state.itemsById,
            [pl.contact.id]:
            {
              ...pl.contact,
              lastText: lt
            },
          }
        }
      }
    }
    case WidgetContactActionTypes.GET_WIDGET_CONTACTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case WidgetContactActionTypes.SET_CURRENT_WIDGET_CONTACT:
      return {
        ...state,
        currentContact: action.payload,
      };
      case WidgetContactActionTypes.UPDATE_WIDGET_CONTACT_START:
        return {
          ...state,
          isUpdatingContact: true,
        };
      case WidgetContactActionTypes.UPDATE_WIDGET_CONTACT_SUCCESS: {
        const pl = action.payload;
        console.log('PL', pl)
        return {
          ...state,
          itemsById: {
            ...state.itemsById,
            [pl.id]: {
              ...pl
            }
          },
          isFetching: false,
          error: null,
        };
      }
      case WidgetContactActionTypes.UPDATE_WIDGET_CONTACT_FAILURE:
        return {
          ...state,
          isUpdatingContact: false,
          error: action.payload,
        };
    default:
      return state;
  }
};

export default widgetContactReducer;

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';

import { setCurrentContact, updateContactStart } from '../../redux/contact/contact.actions.js';

import { selectContactsbyNumber } from '../../redux/contact/contact.selectors';
import { selectEmployeesById } from '../../redux/employee/employee.selectors';
import { selectUser } from '../../redux/user/user.selectors';

const ContactsList = (props) => {
  const searchText = props.searchText;
  const [showModal, setShowModal] = useState(false);
  const [userInformation, setUserInformation] = useState({
    id: '',
    phoneNumber: '',
    name: ''
  });

  const setInitHiddenUserState = (customer) => {
    let returnState = {};
    for (const id in props.employeesById) {
      const employee = props.employeesById[id];
      const findHiddenUser = customer.hiddenUsers[employee.id];
      const initValue = findHiddenUser ? true : false;
      returnState[employee.id] = { value: initValue, changed: false, initValue };
    }
    return returnState;
  }

  const setHiddenUserState = (event) => {
    const { checked, name } = event.target;
    const initValue = userInformation.hiddenUsers[name].initValue;
    setUserInformation({
      ...userInformation,
      hiddenUsers: {
        ...userInformation.hiddenUsers,
        [name]: { value: checked, changed: checked !== initValue, initValue }
      }
    })
  }

  const contactClick = (customer) => {
    setUserInformation({
      id: customer.id,
      phoneNumber: customer.phoneNumber,
      name: customer.name ? customer.name : '',
      hiddenUsers: setInitHiddenUserState(customer)
    })
    openModal();
  };

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleEditContactModalChange = (event) => {
    const { value, name } = event.target;
    setUserInformation({ ...userInformation, [name]: value });
  };

  const handleEditContactModalSubmit = async (event) => {
    event.preventDefault();
    if (userInformation.name.trim() !== '' || userInformation.phoneNumber.trim() !== '') {
      props.updateContactStart(userInformation.id, userInformation, props.currentUser);
      setUserInformation({
        id: '',
        name: '',
        phoneNumber: '',
      });
      closeModal();
    } else alert('Please enter a name');
  };

  const hiddenCustomers = () => {
    let cl = [];
    for (const id in userInformation.hiddenUsers) {
      const e = props.employeesById[id];
      cl.push(<span key={e.id}><label>{e.email}</label><input type="checkbox" name={e.id} onChange={setHiddenUserState} checked={userInformation.hiddenUsers[e.id].value} value={userInformation.hiddenUsers[e.id].value}></input><br /></span>)
    }
    return cl
  };

  const createContactList = () => {
    if (!props.contacts)
      return '';

    const contactRowList = [];
    const userArray = [];
    for (let contactId in props.contacts) {
      const contact = props.contacts[contactId];
      userArray.push(contact);
    }

    // Sort the contacts
    if (userArray.length > 1) {
      userArray.sort(function (a, b) { return new Date(b.lastTextDate !== null ? b.lastTextDate : '1900-01-01') - new Date(a.lastTextDate !== null ? a.lastTextDate : '1900-01-01') });
    }

    for (let j = 0; j < userArray.length; j++) {
      let cus = userArray[j];
      let found = cus.hiddenUsers[props.currentUser.id] ? true : false;
      if ((!found || props.currentUser.roleId === 1) && (!searchText || ((cus.phoneNumber && cus.phoneNumber.includes(searchText)) || (cus.name && cus.name.includes(searchText))))) {
        contactRowList.push(
          <div className="nk-tb-item" key={cus.phoneNumber}>
            <div className="nk-tb-col">
              <div className="user-card">
                <div className="user-avatar xs bg-primary">
                  <span>{cus.name ? cus.name[0].toUpperCase() : 'UN'}</span>
                </div>
                <div className="user-name">
                  <span className="tb-lead">{cus.name || 'Unknown Name'}</span>
                </div>
              </div>
            </div>
            <div className="nk-tb-col tb-col-md">
              <span>Contact</span>
            </div>
            <div className="nk-tb-col tb-col-md">
              <span>{cus.phoneNumber}</span>
            </div>
            <div className="nk-tb-col tb-col-lg">
              <span>{moment(cus.lastTextDate).calendar()}</span>
            </div>
            <div className="nk-tb-col">
              <span className="tb-status text-success">Active</span>
            </div>
            <div className="nk-tb-col nk-tb-col-tools">
              <ul className="nk-tb-actions gx-2">
                <li className="nk-tb-action-hidden" onClick={contactClick.bind(this, cus)}>
                  <button className="btn btn-sm btn-icon btn-trigger" data-toggle="tooltip" data-placement="top" title="Edit Contact">
                    <em className="icon ni ni-edit"></em>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        );
      }
    }
    return contactRowList;
  };

  return (
    <React.Fragment>
      {createContactList()}
      <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex="-1" id="modalForm" style={{ display: `${showModal ? 'block' : 'none'}` }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Contact Info</h5>
              <button className="close" aria-label="Close" onClick={closeModal}>
                <em className="icon ni ni-cross"></em>
              </button>
            </div>
            <div className="modal-body">
              <form action="#" className="form-validate is-alter">
                <div className="form-group">
                  <label className="form-label" htmlFor="phone-no">Phone No</label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control"
                      name="phoneNumber"
                      id="phoneNumber"
                      disabled
                      value={userInformation.phoneNumber}
                      onChange={handleEditContactModalChange} />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="pay-amount">Name</label>
                  <div className="form-control-wrap">
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      name="name"
                      label="Name"
                      value={userInformation.name}
                      onChange={handleEditContactModalChange}
                    />
                  </div>
                </div>

                {props.currentUser.roleId === 1 ? <h4>Hide from users</h4> : ''}
                {props.currentUser.roleId === 1 ? hiddenCustomers() : ''}
                <div className="form-group">
                  <button type="submit" className="btn btn-lg btn-primary" onClick={handleEditContactModalSubmit}>Save</button>
                </div>
              </form>
            </div>
            <div className="modal-footer bg-light">
              <span className="sub-text"></span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>)
};

const mapStateToProps = createStructuredSelector({
  contacts: selectContactsbyNumber,
  employeesById: selectEmployeesById,
  currentUser: selectUser
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentContact: (num) => dispatch(setCurrentContact(num)),
  updateContactStart: (id, userInformation, currentUser) => dispatch(updateContactStart({ id, ...userInformation, currentUser })),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContactsList);

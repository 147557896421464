import { createSelector } from 'reselect';

const selectWM = (state) => state.widgetMessages;

export const selectWidgetMessagesByContact = createSelector(
  [selectWM],
  (messages) => messages.itemsByContactId
);

export const selectCurrentId = createSelector(
  [selectWM],
  (messages) => messages.currentId
);
export const selectCurrentContactMessages = createSelector(
  [selectWM],
  (messages) => messages.itemsByContactId[messages.currentId]
);

export const selectIsWidgetMessagesFetching = createSelector(
  [selectWM],
  (messages) => messages.isFetching
);
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import FormInput from '../../components/form-input/form-input.component';

import { selectError } from '../../redux/user/user.selectors';
import { signInStart } from '../../redux/user/user.actions';

const RegisterForm = (props) => {

  const currentError = props.currentError;
  const [userCredentials, setCredentials] = useState({
    email: '',
    password: '',
    password2: '',
    organization: '',
  });

  const [error, setError] = useState(currentError ? (typeof currentError.response.data.message === Array ? currentError.response.data.message.join(', ') : currentError.response.data.message) : '');

  React.useEffect(() => {

    setError(currentError ? (typeof currentError.response.data.message === Array ? currentError.response.data.message.join(', ') : currentError.response.data.message) : '')
  }, [currentError]);

  const { email, password, password2, organization } = userCredentials;

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!email.trim() || !password.trim() || !password2.trim() || !organization.trim()) {
      setError('Email, password and organization cannot be blank')
      return;
    }
    props.signInStart(email, password, organization);
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setCredentials({ ...userCredentials, [name]: value });
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormInput
        placeholder="Enter your organization"
        type="organization"
        name="organization"
        label="Organization"
        value={organization}
        onChange={handleChange}
      />
      <FormInput
        placeholder="Enter your email address"
        type="email"
        name="email"
        label="Email"
        value={email}
        onChange={handleChange}
      />
      <FormInput
        placeholder="Enter your password"
        type="password"
        name="password"
        label="Password"
        value={password}
        onChange={handleChange}
      />
      <FormInput
        placeholder="Enter your password again"
        type="password"
        name="password2"
        label="Password Again"
        value={password2}
        onChange={handleChange}
      />
      <div className="form-group">
        <span style={{ color: "red" }}>{error}</span>
      </div>
      <div className="form-group">
        <button className="btn btn-lg btn-primary btn-block">Sign in</button>
      </div>
    </form >
  );
};

const mapStateToProps = createStructuredSelector({
  currentError: selectError
});

const mapDispatchToProps = (dispatch) => ({
  signInStart: (email, password, organization) => dispatch(signInStart({ email, password, organization })),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectCurrentId } from '../../redux/widget-messages/widget-messages.selectors';
import { selectWidgetContactsbyId } from '../../redux/widget-contact/widget-contact.selectors';
import { selectUser } from '../../redux/user/user.selectors';

import { newWidgetMessageStart, setReadStart } from '../../redux/widget-messages/widget-messages.actions';

const WidgetMessageChat = (props) => {
  const [textBody, setTextBody] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (textBody.trim() !== '') {
      const currentContact = props.widgetContacts[props.currentId];
      props.newWidgetMessageStart(currentContact, textBody, props.employee.user);
      setTextBody('');
    } else alert('Please enter a message');
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setTextBody(value);
  };

  const setRead = () => {
    if (props.currentContact.unreadMessage) {
      props.setReadStart(props.currentContact.id)
    }
  };

  return (
    <div className="nk-chat-editor">
      <div className="nk-chat-editor-form">
        <div className="form-control-wrap">
          <textarea
            className="form-control form-control-simple no-resize"
            rows="1"
            id="default-textarea"
            placeholder="Type your message..."
            spellCheck="false"
            name="textBody"
            value={textBody}
            maxLength="255"
            onFocus={setRead}
            onChange={handleChange}></textarea>
        </div>
      </div>
      <ul className="nk-chat-editor-tools g-2">
        <li>
          <button className="btn btn-round btn-primary btn-icon"
            onClick={handleSubmit}><em className="icon ni ni-send-alt"></em></button>
        </li>
      </ul>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  currentId: selectCurrentId,
  employee: selectUser,
  widgetContacts: selectWidgetContactsbyId
});

const mapDispatchToProps = (dispatch) => ({
  newWidgetMessageStart: (currentContact, message, employee) =>
    dispatch(newWidgetMessageStart(currentContact, message, employee)),
  setReadStart: (num) => dispatch(setReadStart(num)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WidgetMessageChat);

import { takeLatest, put, all, call } from 'redux-saga/effects';
import axios from 'axios';

import WidgetContactActionTypes from './widget-contact.types';

import {
  getWidgetContactsSuccess,
  getWidgetContactsFailure
} from './widget-contact.actions';

const URL = process.env.REACT_APP_API_URL;

export function* getWidgetContacts() {
  try {
    const { data } = yield axios.get(`${URL}/widget-contacts`)
    yield put(getWidgetContactsSuccess(data));
  } catch (error) {
    yield put(getWidgetContactsFailure(error));
  }
}

export function* onGetWidgetContactsStart() {
  yield takeLatest(WidgetContactActionTypes.GET_WIDGET_CONTACTS_START, getWidgetContacts);
}

export function* widgetContactSagas() {
  yield all([call(onGetWidgetContactsStart)]);
}

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';

import {
  selectCurrentNumberMessages,
  selectCurrentNumber,
} from '../../redux/messages/messages.selectors';
import { selectEmployeesById } from '../../redux/employee/employee.selectors';
import { selectContactsbyNumber } from '../../redux/contact/contact.selectors';
import { selectShowChat } from '../../redux/ui/ui.selectors';
import MessageChat from '../../components/message-chat/message-chat.component';

import {
  setShowChatBody,
} from '../../redux/ui/ui.actions';

const MessageConversation = (props) => {
  let messagesEnd;
  const scrollToBottom = () => {
    if (messagesEnd)
      messagesEnd.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  });

  const [showProfile, setShowProfile] = useState(false);

  if (!props.currentNumber || !props.contacts) return (<span></span>);

  const currentNumber = props.currentNumber;
  const currentContact = props.contacts[currentNumber];

  const loopMessages = () => {
    let ml = [];
    if (props.messages) {
      const messageLength = props.messages.length;
      for (let i = 0; i < messageLength; i++) {
        const message = props.messages[i];
        let showMeta = true;
        if (i < messageLength - 1) {
          let nextMessage = props.messages[i + 1];
          if (message.incomingMessage === nextMessage.incomingMessage) showMeta = false;
        }
        ml.push(
          <span key={message.createdDate}>
            {!message.incomingMessage
              ? employeeMessage(
                message.input,
                message.userId,
                message.createdDate,
                showMeta
              )
              : contactMessage(message.input, message.contact.phoneNumber, message.createdDate, showMeta)}
          </span>
        )
      }
    }
    return ml;
  };

  // eslint-disable-next-line
  const handleShowProfile = () => {
    setShowProfile(!showProfile)
  };

  const hideChatBody = () => {
    // Hide chat
    props.setShowChatBody(false)
  }

  const employeeMessage = (currentinput, employeeId, eventdate, showMeta, key) => {
    const employeeName = props.employeesById[employeeId]
      ? props.employeesById[employeeId].email
      : 'Unknown';
    const md = (
      <div className="chat is-me" key={key}>
        <div className="chat-content">
          <div className="chat-bubbles">
            <div className="chat-bubble">
              <div className="chat-msg">{currentinput}</div>

            </div>
          </div>
          <ul className="chat-meta">
            <li>{employeeName}</li>
            <li>{moment(eventdate).calendar()}</li>
          </ul>
        </div>
      </div>
    );
    return md;
  };

  const contactMessage = (currentinput, useridentifier, eventdate, showMeta, key) => {
    const md = (
      <div className="chat is-you" key={key}>
        <div className="chat-avatar">
          <div className="user-avatar bg-purple">
            <span>{currentContact.name ? currentContact.name[0] : 'UN'}</span>
          </div>
        </div>
        <div className="chat-content">
          <div className="chat-bubbles">
            <div className="chat-bubble">
              <div className="chat-msg"> {currentinput} </div>
            </div>
          </div>
          <ul className="chat-meta">
            {/* <li>{useridentifier}</li> */}
            <li>{moment(eventdate).calendar()}</li>
          </ul>
        </div>
      </div>
    );
    return md;
  };

  return (
    <div className={`nk-chat-body ${showProfile ? ' profile-shown' : ''} ${props.selectShowChat ? ' show-chat' : ''}`}>
      <div className="nk-chat-head">
        <ul className="nk-chat-head-info">
          <li className="nk-chat-body-close">
            <button onClick={hideChatBody} className="btn btn-icon btn-trigger nk-chat-hide ml-n1"><em className="icon ni ni-arrow-left"></em></button>
          </li>
          <li className="nk-chat-head-user">
            <div className="user-card">
              <div className="user-avatar bg-purple">
                <span>{currentContact.name ? currentContact.name[0] : 'UN'}</span>
              </div>
              <div className="user-info">
                <div className="lead-text">{currentNumber
                  ? `To: ${currentContact.name ? currentContact.name : 'Unknown Name'}`
                  : 'Select a number on the left to see messages (If no messages appear try refreshing the page)'}</div>
                <span className="sub-text">{currentNumber
                  ? `${currentNumber}`
                  : ''}</span>
              </div>
            </div>
          </li>
        </ul>
        {/* <ul className="nk-chat-head-tools">
          <li className="d-none d-xl-block">
            <div className="dropdown">
              <button className="dropdown-toggle btn btn-icon btn-trigger text-primary" data-toggle="dropdown"><em className="icon ni ni-setting-fill"></em></button>
              <div className="dropdown-menu dropdown-menu-right">
                <ul className="link-list-opt no-bdr">
                  <li><a className="dropdown-item" href="#"><em className="icon ni ni-archive"></em><span>Make as Archive</span></a></li>
                  <li><a className="dropdown-item" href="#"><em className="icon ni ni-cross-c"></em><span>Remove Conversion</span></a></li>
                </ul>
              </div>
            </div>
          </li>
          <li className="d-none d-xl-block"><button className="btn btn-icon btn-trigger text-primary chat-profile-toggle" onClick={handleShowProfile}><em className="icon ni ni-alert-circle-fill"></em></button></li>
        </ul> */}
      </div>
      <div className="nk-chat-panel" data-simplebar>
        <div>
          {loopMessages()}
        </div>
        <div
          style={{ float: 'left', clear: 'both' }}
          ref={(el) => {
            messagesEnd = el;
          }}
        ></div>
      </div>
      <MessageChat currentContact={currentContact} />
      {/* <div className={`nk-chat-profile ${showProfile ? 'visible' : ''}`} data-simplebar>
        <div className="user-card user-card-s2 my-4">
          <div className="user-avatar md bg-purple">
            <span>{currentContact.name ? currentContact.name[0] : 'UN'}</span>
          </div>
          <div className="user-info">
            <h5>{currentContact.name ? currentContact.name : 'Unknown Name'}</h5>
          </div>

        </div>
        <div className="chat-profile">
          <div className="chat-profile-group">
            <a href="#" className="chat-profile-head" data-toggle="collapse" data-target="#chat-options">
              <h6 className="title overline-title">Options</h6>
              <span className="indicator-icon"><em className="icon ni ni-chevron-down"></em></span>
            </a>
            <div className="chat-profile-body collapse show" id="chat-options">
              <div className="chat-profile-body-inner">
                <ul className="chat-profile-options">
                  <li><a className="chat-option-link" href="#"><em className="icon icon-circle bg-light ni ni-edit-alt"></em><span className="lead-text">Nickname</span></a></li>
                </ul>
              </div>
            </div>
          </div>
          <div className="chat-profile-group">
            <a href="#" className="chat-profile-head" data-toggle="collapse" data-target="#chat-settings">
              <h6 className="title overline-title">Settings</h6>
              <span className="indicator-icon"><em className="icon ni ni-chevron-down"></em></span>
            </a>
            <div className="chat-profile-body collapse show" id="chat-settings">
              <div className="chat-profile-body-inner">
                <ul className="chat-profile-settings">
                  <li>
                    <div className="custom-control custom-control-sm custom-switch">
                      <input type="checkbox" className="custom-control-input" id="customSwitch2" />
                      <label className="custom-control-label" for="customSwitch2">Notifications</label>
                    </div>
                  </li>
                  <li>
                    <a className="chat-option-link" href="#">
                      <em className="icon icon-circle bg-light ni ni-bell-off-fill"></em>
                      <div>
                        <span className="lead-text">Ignore Messages</span>
                        <span className="sub-text">You won’t be notified when message you.</span>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a className="chat-option-link" href="#">
                      <em className="icon icon-circle bg-light ni ni-alert-fill"></em>
                      <div>
                        <span className="lead-text">Something Wrong</span>
                        <span className="sub-text">Give feedback and report conversion.</span>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  messages: selectCurrentNumberMessages,
  contacts: selectContactsbyNumber,
  currentNumber: selectCurrentNumber,
  employeesById: selectEmployeesById,
  selectShowChat: selectShowChat
});


const mapDispatchToProps = (dispatch) => ({
  setShowChatBody: (b) => dispatch(setShowChatBody(b))
});

export default connect(
  mapStateToProps, mapDispatchToProps
)(MessageConversation);

import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import avatar from 'assets/media/blank-profile-picture.png'

import {
  selectWidgetMessagesByContact
} from 'redux/widget-messages/widget-messages.selectors';

import {
  getWidgetContactMessagesStart,
} from 'redux/widget-messages/widget-messages.actions';

import {
  setShowWidgetChatBody,
} from 'redux/ui/ui.actions';

import {
  selectUser
} from 'redux/user/user.selectors';

import { selectWidgetContacts } from 'redux/widget-contact/widget-contact.selectors';
import { setCurrentId } from 'redux/widget-messages/widget-messages.actions.js';

const MessageUserList = (props) => {

  const numberClick = (id, e) => {
    props.setCurrentId(id);
    if (!props.widgetMessagesByContact[id]) {
      props.getWidgetContactMessagesStart(id);
    }

    // Show chat
    document.querySelectorAll('.chat-open').forEach(input => input.parentElement.classList.remove("current"));
    e.currentTarget.parentElement.classList.add('current')
    props.setShowWidgetChatBody(true)
  };

  const showUnread = (b) => {
    if (!b) return '';

    return (<div className="status unread">
      <em className="icon ni ni-bullet-fill"></em>
    </div>)
  }

  const createMessageList = () => {
    const ml = [];

    if (props.contacts && props.currentUser) {
      for (let j = 0; j < props.contacts.length; j++) {

        const contact = props.contacts[j];
        ml.push(
          <li className={`chat-item ${contact.unreadMessage ? 'is-unread' : ''}`} key={contact.id}>
            <a href="#!" className="chat-link chat-open" onClick={numberClick.bind(this, contact.id)}>
              <div className="chat-media user-avatar">
                <img src={avatar} alt="" />
              </div>
              <div className="chat-info">
                <div className="chat-from">
                  <div className="name">{contact.name || 'Unknown Name'} </div>
                  <span className="time">({moment(contact.lastMessage.createdDate).calendar()})</span>
                </div>
                <div className="chat-context">
                  <div className="text">
                    <p>{`${!contact.lastMessage.incomingMessage ? 'You: ' : 'Them: '}${contact.lastMessage.input}`}</p>
                  </div>
                  {showUnread(contact.unreadMessage)}
                </div>
              </div>
            </a>
          </li>
        );
      }
    }
    return ml;
  };

  return (
    <div className="nk-chat-aside-body" data-simplebar>
      <div className="nk-chat-list">
        <h6 className="title overline-title-alt">Chats</h6>
        <ul className="chat-list">
          {createMessageList()}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  contacts: selectWidgetContacts,
  widgetMessagesByContact: selectWidgetMessagesByContact,
  currentUser: selectUser
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentId: (id) => dispatch(setCurrentId(id)),
  getWidgetContactMessagesStart: (id) => dispatch(getWidgetContactMessagesStart(id)),
  setShowWidgetChatBody: (b) => dispatch(setShowWidgetChatBody(b))
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageUserList);

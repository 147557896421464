import React from 'react';
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux';

const Header = () => {
  const { user } = useSelector(({ auth }) => ({
    user: auth.user,
  }));

  const location = useLocation();
  const pathName = () => {
    if (location.pathname === '/dashboard') {
      return 'Dashboard'
    } else if (location.pathname === '/chat') {
      return 'Chat'
    } else if (location.pathname === '/contacts') {
      return 'Contacts'
    } else if (location.pathname === '/phone') {
      return 'Phone'
    } else if (location.pathname === '/widget-chat') {
      return 'Browser Chat'
    } else if (location.pathname === '/account-settings/personal-info'
      || location.pathname === '/account-settings/notifications'
      || location.pathname === '/account-settings/settings') {
      return 'Account Settings'
    }
  }
  if (!user) return null;
  return (
    <div className="nk-header nk-header-fixed is-light">
      <div className="container-fluid">
        <div className="nk-header-wrap">
          <div className="nk-menu-trigger d-xl-none ml-n1">
            <button className="nk-nav-toggle nk-quick-nav-icon linkButton" data-target="sidebarMenu"><em className="icon ni ni-menu"></em></button>
          </div>
          <div className="nk-header-app-name">
            <div className="nk-header-app-logo">
              <em className="icon ni ni-chat bg-purple-dim"></em>
            </div>
            <div className="nk-header-app-info">
              <span className="sub-text">TXTN</span>
              <span className="lead-text">{pathName()}</span>
            </div>
          </div>
          <div className="nk-header-tools">
            <ul className="nk-quick-nav">
              <li className="dropdown user-dropdown">
                <button className="dropdown-toggle mr-n1 linkButton" data-toggle="dropdown">
                  <div className="user-toggle">
                    <div className="user-avatar sm">
                      <em className="icon ni ni-user-alt"></em>
                    </div>
                  </div>
                </button>
                <div className="dropdown-menu dropdown-menu-md dropdown-menu-right">
                  <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                    <div className="user-card">
                      <div className="user-avatar">
                        <span>{user.email ? user.email[0] : ''}</span>
                      </div>
                      <div className="user-info">
                        <span className="lead-text">{user.email}</span>
                        {/* <span className="sub-text">info@softnio.com</span> */}
                      </div>
                    </div>
                  </div>
                  <div className="dropdown-inner">
                    <ul className="link-list">
                      <li><a href="/account-settings"><em className="icon ni ni-setting-alt"></em><span>Account Setting</span></a></li>
                    </ul>
                  </div>
                  <div className="dropdown-inner">
                    <ul className="link-list">
                      <li><a href="/logout"><em className="icon ni ni-signout"></em><span>Sign out</span></a></li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div >
  );
};

export default Header;

import { createSelector } from 'reselect';

const selectContacts = (state) => state.contacts;

export const selectContactsbyNumber = createSelector(
  [selectContacts],
  (contacts) => contacts.itemsByNumber
);

export const selectContactsWithTexts = createSelector(
  [selectContacts],
  (contacts) => {
    let rArray = [];
    for (let n in contacts.itemsByNumber) {
      let c = contacts.itemsByNumber[n];
      if (c.lastText) rArray.push(c)
    }
    rArray.sort(function (a, b) { return new Date(b.lastText.createdDate) - new Date(a.lastText.createdDate) });
    return rArray;
  }
);

export const selectCurrentContact = createSelector(
  [selectContacts],
  (contacts) => contacts.currentContact
);

export const selectIsContactsFetching = createSelector(
  [selectContacts],
  (contacts) => contacts.isFetching
);

import { all, call } from 'redux-saga/effects';

import { userSagas } from './user/user.sagas';
import { messageSagas } from './messages/messages.sagas';
import { employeeSagas } from './employee/employee.sagas';
import { contactSagas } from './contact/contact.sagas';
import { widgetContactSagas } from './widget-contact/widget-contact.sagas';
import { widgetMessageSagas } from './widget-messages/widget-messages.sagas';
import { sessionSagas } from './session/session.sagas';

export default function* rootSaga() {
  yield all([
    call(userSagas),
    call(messageSagas),
    call(employeeSagas),
    call(contactSagas),
    call(widgetContactSagas),
    call(widgetMessageSagas),
    call(sessionSagas)
  ]);
}

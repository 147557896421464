import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectUser } from '../../redux/user/user.selectors';

import { setCurrentNumber } from '../../redux/messages/messages.actions.js';
import { newTextStart } from '../../redux/messages/messages.actions';

const NewMessageIcon = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [userInformation, setUserInformation] = useState({
    message: '',
    phoneNumber: '',
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (userInformation.message.trim() !== '' || userInformation.phoneNumber.trim() !== '') {
      if (userInformation.phoneNumber.length === 10) {
        props.newTextStart(`+1${userInformation.phoneNumber}`, userInformation.message, props.employee.user);
        setUserInformation({
          message: '',
          phoneNumber: '',
        });
        setShowModal(false);
      } else alert('Please enter a 10 digit number');
    } else alert('Please enter a message');
  };

  const handleChange = (event) => {
    const { value, name } = event.target;
    setUserInformation({ ...userInformation, [name]: value });
  };

  const { phoneNumber, message } = userInformation;

  return (
    <React.Fragment>
      <button className="btn btn-round btn-icon btn-light" onClick={() => setShowModal(!showModal)}>
        <em className="icon ni ni-edit-alt-fill"></em>
      </button>
      <div className={`modal fade ${showModal ? 'show' : ''}`} tabIndex="-1" id="modalForm" style={{ display: `${showModal ? 'block' : 'none'}` }}>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">New Text</h5>
              <button className="close" data-dismiss="modal" aria-label="Close" onClick={() => setShowModal(!showModal)}>
                <em className="icon ni ni-cross"></em>
              </button>
            </div>
            <div className="modal-body">
              <form action="#" className="form-validate is-alter">
                <div className="form-group">
                  <label className="form-label" htmlFor="phone-no">Phone No</label>
                  <div className="form-control-wrap">
                    <div className="form-icon form-icon-left" style={{ color: "black" }}>+1</div>
                    <input
                      type="number"
                      className="form-control"
                      name="phoneNumber"
                      id="phoneNumber"
                      value={phoneNumber}
                      onChange={handleChange} />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label" htmlFor="pay-amount">Text</label>
                  <div className="form-control-wrap">
                    <textarea
                      type="text"
                      className="form-control"
                      id="message"
                      name="message"
                      label="Message"
                      value={message}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <button type="submit" className="btn btn-lg btn-primary" onClick={handleSubmit}>Send</button>
                </div>
              </form>
            </div>
            <div className="modal-footer bg-light">
              <span className="sub-text"></span>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" id="backdrop" style={{ display: `${showModal ? 'block' : 'none'}` }}></div>
    </React.Fragment>

  );
};

const mapStateToProps = createStructuredSelector({
  employee: selectUser,
});


const mapDispatchToProps = (dispatch) => ({
  setCurrentNumber: (num) => dispatch(setCurrentNumber(num)),
  newTextStart: (number, message, employee) =>
    dispatch(newTextStart(number, message, employee)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewMessageIcon);
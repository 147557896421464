import WidgetMessageActionTypes from './widget-messages.types';

const INITIAL_STATE = {
  items: [],
  itemsByContactId: {},
  error: null,
};

const widgetMessageReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case WidgetMessageActionTypes.GET_WIDGET_CONTACT_MESSAGES_START:
      return {
        ...state,
        isFetching: true,
      };
    case WidgetMessageActionTypes.GET_WIDGET_CONTACT_MESSAGES_SUCCESS: {
      const pl = action.payload;
      console.log(pl);
      const itemsSorted = pl.messages.slice().sort((a, b) => {
        var c = new Date(a.createdDate);
        var d = new Date(b.createdDate);
        return c - d;
      });

      let items = state.items;
      if (!items) items = [...pl.messages];
      else items.push(...pl.messages)

      return {
        ...state,
        items,
        itemsByContactId: {
          ...state.itemsByContactId,
          [pl.id]: itemsSorted
        },
        isFetching: false,
        error: null,
      };
    }
    case WidgetMessageActionTypes.GET_WIDGET_CONTACT_MESSAGES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case WidgetMessageActionTypes.NEW_WIDGET_MESSAGE_SUCCESS: {
      const pl = action.payload;
      let items = [...state.items];
      items.push(pl);

      let itemId = state.itemsByContactId[pl.contact.id];
      itemId.push(pl);
      return {
        ...state,
        items,
        itemsByContactId: {
          ...state.itemsByContactId,
          [pl.contact.id]: [...itemId],
        },
      };
    }
    case WidgetMessageActionTypes.RECEIVE_MESSAGE_START: {
      const pl = action.payload;
      let items = [...state.items];
      items.push(pl);

      let itemId = state.itemsByContactId[pl.contact.id];
      if (!itemId) itemId = [];
      itemId.push(pl);
      return {
        ...state,
        items,
        itemsByContactId: {
          ...state.itemsByContactId,
          [pl.contact.id]: [...itemId],
        },
      };
    }
    case WidgetMessageActionTypes.SET_CURRENT_ID:
      return {
        ...state,
        currentId: action.payload,
      };
    default:
      return state;
  }
};

export default widgetMessageReducer;

import UIActionTypes from './ui.types';

export const setShowChatBody = (b) => ({
  type: UIActionTypes.SET_SHOW_CHAT_BODY,
  payload: b,
});

export const setShowWidgetChatBody = (b) => ({
  type: UIActionTypes.SET_SHOW_WIDGET_CHAT_BODY,
  payload: b,
});

export const setBrowserNotifications = (b) => ({
  type: UIActionTypes.SET_BROWSER_NOTIFICATIONS,
  payload: b,
});

export const setSoundNotifications = (b) => ({
  type: UIActionTypes.SET_SOUND_NOTIFICATIONS,
  payload: b,
});

export const setEnterToCall = (b) => ({
  type: UIActionTypes.SET_ENTER_TO_CALL,
  payload: b,
});
import MessageActionTypes from './messages.types';

export const getTextsStart = () => ({
  type: MessageActionTypes.GET_TEXTS_START,
});

export const getTextsSuccess = (messages) => ({
  type: MessageActionTypes.GET_TEXTS_SUCCESS,
  payload: messages,
});

export const getTextsFailure = (error) => ({
  type: MessageActionTypes.GET_TEXTS_FAILURE,
  payload: error,
});

export const getContactsTextsStart = (id, phoneNumber) => ({
  type: MessageActionTypes.GET_CONTACTS_TEXTS_START,
  payload: { id, phoneNumber }
});

export const getContactTextsSuccess = (phoneNumber, messages) => ({
  type: MessageActionTypes.GET_CONTACTS_TEXTS_SUCCESS,
  payload: { phoneNumber, messages }
});

export const getContactTextsFailure = (error) => ({
  type: MessageActionTypes.GET_CONTACTS_TEXTS_FAILURE,
  payload: error,
});

export const setCurrentNumber = (number) => ({
  type: MessageActionTypes.SET_CURRENT_NUMBER,
  payload: number,
});

export const newTextWebsocket = (message) => ({
  type: MessageActionTypes.NEW_TEXT_WEBSOCKET,
  payload: message,
});

export const newTextStart = (number, message) => ({
  type: MessageActionTypes.NEW_TEXT_START,
  payload: { number, message },
});

export const newTextSuccess = (message) => ({
  type: MessageActionTypes.NEW_TEXT_SUCCESS,
  payload: message,
});

export const newTextFailure = (error) => ({
  type: MessageActionTypes.NEW_TEXT_FAILURE,
  payload: error,
});

export const setReadStart = (contactId) => ({
  type: MessageActionTypes.SET_READ_START,
  payload: contactId,
})

export const setReadSuccess = (contact) => ({
  type: MessageActionTypes.SET_READ_SUCCESS,
  payload: contact,
});

export const setReadFailure = (error) => ({
  type: MessageActionTypes.SET_READ_FAILURE,
  payload: error,
});
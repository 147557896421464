import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectIsContactsFetching } from '../../redux/contact/contact.selectors';
import WithSpinner from '../../components/with-spinner/with-spinner.component';
import ContactWidget from './contacts-widget.component';

const mapStateToProps = createStructuredSelector({
  isLoading: (state) => selectIsContactsFetching(state),
});

const ContactWidgetContainer = connect(mapStateToProps)(WithSpinner(ContactWidget));

export default ContactWidgetContainer;

const UserActionTypes = {
  SIGN_IN_START: 'SIGN_IN_START',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
  SIGN_OUT_START: 'SIGN_OUT_START',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SIGN_OUT_FAILURE: 'SIGN_OUT_FAILURE',
  CHECK_USER_SESSION: 'CHECK_USER_SESSION',
  USER_LOADED: 'USER_LOADED',
  GENERATE_TWILIO_TOKEN: 'GENERATE_TWILIO_TOKEN',
  TWILIO_TOKEN_LOADED: 'TWILIO_TOKEN_LOADED',
  TOGGLE_ORG_ONLINE_START: 'TOGGLE_ORG_ONLINE_START',
  TOGGLE_ORG_ONLINE_SUCCESS: 'TOGGLE_ORG_ONLINE_SUCCESS',
  TOGGLE_ORG_ONLINE_FAILURE: 'TOGGLE_ORG_ONLINE_FAILURE'
};

export default UserActionTypes;

import { takeLatest, put, all, call } from 'redux-saga/effects';
import axios from 'axios';

import WidgetMessageActionTypes from './widget-messages.types';

import {
  getWidgetContactMessagesSuccess,
  getWidgetContactMessagesFailure,
  newWidgetMessageSuccess,
  newWidgetMessageFailure,
  setReadSuccess,
  setReadFailure
} from './widget-messages.actions';

const URL = process.env.REACT_APP_API_URL;

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function* getWidgetContactMessages(pl) {
  const { payload } = pl;
  try {
    const r = yield Promise.all([
      axios.get(`${URL}/widget-messages?contactId=${payload.id}`),
      timeout(500)
    ]);
    const { data } = r[0];
    console.log(data)

    yield put(getWidgetContactMessagesSuccess(payload.id, data));
  } catch (error) {
    console.log(error);
    yield put(getWidgetContactMessagesFailure(error));
  }
}

export function* sendMessage(item) {
  try {
    const { currentContact, message } = item.payload;
    const { data } = yield axios.post(`${URL}/widget-messages`, {
      userUUID: currentContact.uuid,
      email: currentContact.email,
      name: currentContact.name,
      organizationId: currentContact.organizationId,
      sessionId: currentContact.sessionId,
      input: message,
      incomingMessage: false
    });

    data.contact = currentContact;
    yield put(newWidgetMessageSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(newWidgetMessageFailure(error));
  }
}

export function* readMessage(item) {
  try {
    const { data } = yield axios.post(`${URL}/widget-messages/read`, {
      contactId: item.payload
    });
    yield put(setReadSuccess(data));
  } catch (error) {
    console.log(error);
    yield put(setReadFailure(error));
  }
}
export function* onReadMessageStart() {
  yield takeLatest(WidgetMessageActionTypes.SET_WIDGET_READ_START, readMessage)
}

export function* onGetWidgetContactMessagesStart() {
  yield takeLatest(WidgetMessageActionTypes.GET_WIDGET_CONTACT_MESSAGES_START, getWidgetContactMessages);
}

export function* onNewWidgetMessageStart() {
  yield takeLatest(WidgetMessageActionTypes.NEW_WIDGET_MESSAGE_START, sendMessage);
}

export function* widgetMessageSagas() {
  yield all([call(onGetWidgetContactMessagesStart), call(onNewWidgetMessageStart), call(onReadMessageStart)]);
}

import UserActionTypes from './user.types';

const INITIAL_STATE = {
  user: null,
  twilioToken: null,
  error: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SIGN_IN_SUCCESS: {
      const { accessToken, user } = action.payload;
      return { ...state, accessToken, user: user, error: null };
    }
    case UserActionTypes.USER_LOADED: {
      const { user } = action.payload;
      return { 
        ...state, 
        user,
        error: null 
      };
    }
    case UserActionTypes.TWILIO_TOKEN_LOADED: {
      const { ...token } = action.payload;
      return { ...state, twilioToken: token.token };
    }
    case UserActionTypes.SIGN_OUT_SUCCESS:
      return INITIAL_STATE;
    case UserActionTypes.SIGN_IN_FAILURE:
    case UserActionTypes.SIGN_OUT_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case UserActionTypes.TOGGLE_ORG_ONLINE_SUCCESS:
      console.log(action.payload)
      const user = state.user;
      const org = state.user.organization;
      return {
        ...state,
        user: {
          ...user,
          organization: {
            ...org,
            isOnline: action.payload
          }
        }
      }
    default:
      return state;
  }
};

export default userReducer;

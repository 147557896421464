import React from 'react';

const SideBarMobile = () => (
      
                <div className="nk-sidebar" data-content="sidebarMenu">
                    <div className="nk-sidebar-inner" data-simplebar>
                        <ul className="nk-menu nk-menu-md">
                            <li className="nk-menu-heading">
                                <h6 className="overline-title text-primary-alt">Dashboards</h6>
                            </li>
                            <li className="nk-menu-item">
                                <a href="/dashboard" className="nk-menu-link">
                                    <span className="nk-menu-icon"><em className="icon ni ni-dashboard"></em></span>
                                    <span className="nk-menu-text">Default Dashboard</span>
                                </a>
                            </li>
                            <li className="nk-menu-heading">
                                <h6 className="overline-title text-primary-alt">Modules</h6>
                            </li>
                            <li className="nk-menu-item">
                                <a href="/chat" className="nk-menu-link">
                                    <span className="nk-menu-icon"><em className="icon ni ni-chat"></em></span>
                                    <span className="nk-menu-text">Chat</span>
                                </a>
                            </li>
                            <li className="nk-menu-item">
                                <a href="/contacts" className="nk-menu-link">
                                    <span className="nk-menu-icon"><em className="icon ni ni-users"></em></span>
                                    <span className="nk-menu-text">Contacts</span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
    );

export default SideBarMobile;
import WidgetMessageActionTypes from './widget-messages.types';

export const getWidgetContactMessagesStart = (id) => ({
  type: WidgetMessageActionTypes.GET_WIDGET_CONTACT_MESSAGES_START,
  payload: { id }
});

export const getWidgetContactMessagesSuccess = (id, messages) => ({
  type: WidgetMessageActionTypes.GET_WIDGET_CONTACT_MESSAGES_SUCCESS,
  payload: { id, messages }
});

export const getWidgetContactMessagesFailure = (error) => ({
  type: WidgetMessageActionTypes.GET_WIDGET_CONTACT_MESSAGES_FAILURE,
  payload: error,
});

export const setCurrentId = (id) => ({
  type: WidgetMessageActionTypes.SET_CURRENT_ID,
  payload: id,
});

export const newWidgetMessageStart = (currentContact, message, employee) => ({
  type: WidgetMessageActionTypes.NEW_WIDGET_MESSAGE_START,
  payload: { currentContact, message, employee },
});

export const newWidgetMessageSuccess = (message) => ({
  type: WidgetMessageActionTypes.NEW_WIDGET_MESSAGE_SUCCESS,
  payload: message,
});

export const newWidgetMessageFailure = (error) => ({
  type: WidgetMessageActionTypes.NEW_WIDGET_MESSAGE_FAILURE,
  payload: error,
});

export const receiveWidgetMessageStart = (message) => ({
  type: WidgetMessageActionTypes.RECEIVE_MESSAGE_START,
  payload: message
});

export const receiveWidgetMessageSuccess = (message) => ({
  type: WidgetMessageActionTypes.RECEIVE_MESSAGE_SUCCESS,
  payload: message,
});

export const receiveWidgetMessageFailure = (error) => ({
  type: WidgetMessageActionTypes.RECEIVE_MESSAGE_FAILURE,
  payload: error,
});

export const setReadStart = (contactId) => ({
  type: WidgetMessageActionTypes.SET_WIDGET_READ_START,
  payload: contactId,
})

export const setReadSuccess = (contact) => ({
  type: WidgetMessageActionTypes.SET_WIDGET_READ_SUCCESS,
  payload: contact,
});

export const setReadFailure = (error) => ({
  type: WidgetMessageActionTypes.SET_WIDGET_READ_FAILURE,
  payload: error,
});
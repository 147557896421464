import { CognitoAuth } from 'amazon-cognito-auth-js';
import { CognitoUserPool } from 'amazon-cognito-identity-js'
import { config as AWSConfig } from 'aws-sdk'

const appConfig = {
  "region": "us-east-2",
  "callbackUri": "https://app.mytxtn.com/callback",
  "signoutUri": "https://app.mytxtn.com/logout",
  "tokenScopes": [
      "openid",
      "email",
      "profile"             
  ]
}

if(process.env.NODE_ENV === 'development') {
  appConfig["callbackUri"] = "http://localhost:3000/callback";
  appConfig["signoutUri"] = "http://localhost:3000/logout";
}

AWSConfig.region = appConfig.region

// Creates a CognitoAuth instance
const createCognitoAuth = (aws) => {
  const appWebDomain = `http://${aws.org}.auth.us-east-2.amazoncognito.com`.replace('https://', '').replace('http://', '')

  const auth = new CognitoAuth({
    UserPoolId: aws.cognitoUserPool,
    ClientId: aws.cognitoClientId,
    AppWebDomain: appWebDomain,
    TokenScopesArray: appConfig.tokenScopes,
    RedirectUriSignIn: appConfig.callbackUri,
    RedirectUriSignOut: appConfig.signoutUri
  })
  return auth
}

// Creates a CognitoUser instance
const createCognitoUser = (aws) => {
  const pool = createCognitoUserPool(aws)
  return pool.getCurrentUser()
}

// Creates a CognitoUserPool instance
const createCognitoUserPool = (aws) => new CognitoUserPool({
  UserPoolId: aws.cognitoUserPool,
  ClientId: aws.cognitoClientId
})

// Get the URI of the hosted sign in screen
const getCognitoSignInUri = (aws) => {
  const domain = `http://${aws.org}.auth.us-east-2.amazoncognito.com`;
  const signinUri = `${domain}/login?response_type=code&client_id=${aws.cognitoClientId}&redirect_uri=${appConfig.callbackUri}`
  return signinUri
}

// Parse the response from a Cognito callback URI (assumed a token or code is in the supplied href). Returns a promise.
const parseCognitoWebResponse = (href, aws) => {
  return new Promise((resolve, reject) => {
    const auth = createCognitoAuth(aws)
    // userHandler will trigger the promise
    auth.userhandler = {
      onSuccess: function (result) {
        resolve(result)
      },
      onFailure: function (err) {
        reject(new Error('Failure parsing Cognito web response: ' + err))
      }
    }
    auth.parseCognitoWebResponse(href, aws)
  })
}

// Gets a new Cognito session. Returns a promise.
const getCognitoSession = (aws) => {
  return new Promise((resolve, reject) => {
    const cognitoUser = createCognitoUser(aws)
    cognitoUser.getSession((err, result) => {
      if (err || !result) {
        reject(new Error('Failure getting Cognito session: ' + err))
        return
      }

      // Resolve the promise with the session credentials
      console.debug('Successfully got session: ' + JSON.stringify(result))
      const session = {
        credentials: {
          accessToken: result.accessToken.jwtToken,
          idToken: result.idToken.jwtToken,
          refreshToken: result.refreshToken.token
        },
        user: {
          userName: result.idToken.payload['cognito:username'],
          email: result.idToken.payload.email,
          phoneNumber: result.idToken.payload.phone_number
        }
      }
      resolve(session)
    })
  })
}

// Sign out of the current session (will redirect to signout URI)
const signOutCognitoSession = () => {
  const auth = createCognitoAuth()
  auth.signOut()
}

export default {
  createCognitoAuth,
  createCognitoUser,
  createCognitoUserPool,
  getCognitoSession,
  getCognitoSignInUri,
  parseCognitoWebResponse,
  signOutCognitoSession
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import WidgetMessageUserList from 'containers/addons/widget/message-user-list/MessageUserList';
import WidgetMessageConversationContainer from 'containers/addons/widget/message-conversation/MessageConversation';
import IsOnline from 'components/is-online/is-online.component';

import { selectShowWidgetChat } from 'redux/ui/ui.selectors';
import { selectWidgetAddon } from 'redux/user/user.selectors';

class WidgetChat extends Component {
  render() {
    if (!this.props.selectWidgetAddon) {
      return <Redirect
        to={{
          pathname: '/',
          state: { from: '/widget-contact' },
        }}
      />
    }

    return (
      <div className="nk-content p-0">
        <div className="nk-content-inner">
          <div className="nk-content-body">
            <div className="nk-chat">
              <div className={`nk-chat-aside ${this.props.selectShowWidgetChat ? 'hide-aside' : ''}`}>
                <div className="nk-chat-aside-head">
                  <div className="nk-chat-aside-user">
                    <div className="dropdown">
                      <div className="user-avatar">
                        <img src="./images/avatar/b-sm.jpg" alt="" />
                      </div>
                      <div className="title">Browser Chats</div>
                    </div>
                  </div>
                  <ul className="nk-chat-aside-tools g-2">
                    <li>
                      <IsOnline />
                    </li>
                  </ul>
                </div>
                <WidgetMessageUserList />
              </div>
              <WidgetMessageConversationContainer />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  selectShowWidgetChat: selectShowWidgetChat,
  selectWidgetAddon: selectWidgetAddon
});


export default connect(
  mapStateToProps
)(WidgetChat);
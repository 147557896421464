import ContactActionTypes from './contact.types';
import MessageActionTypes from '../messages/messages.types';

const INITIAL_STATE = {
  error: null
};

const contactReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ContactActionTypes.GET_CONTACTS_START:
      return {
        ...state,
        isFetching: true,
      };
    case ContactActionTypes.GET_CONTACTS_SUCCESS: {
      const pl = action.payload;
      const itemsById = pl.reduce(
        (result, item) => ({
          ...result,
          [item['id']]: item,
        }),
        {}
      );
      const itemsByNumber = pl.reduce(
        (result, item) => {
          const hiddenUsers = {};

          for (let i = 0; i < item.hiddenUsers.length; i++) {
            const hu = item.hiddenUsers[i];
            hiddenUsers[hu.id] = { ...hu }
          }

          return ({
            ...result,
            [item['phoneNumber']]:
            {
              ...item,
              'hiddenUsers': hiddenUsers
            },
          })
        },
        {}
      );
      return {
        ...state,
        items: pl,
        itemsById,
        itemsByNumber,
        isFetching: false,
        error: null,
      };
    }
    case MessageActionTypes.NEW_TEXT_WEBSOCKET: 
    case MessageActionTypes.NEW_TEXT_SUCCESS: {
      const pl = action.payload;
      let itemNumber = state.itemsByNumber[pl.contact.phoneNumber];
      const lt = { ...pl };
      delete lt.contact;

      if (!itemNumber) {
        return {
          ...state,
          itemsByNumber: {
            ...state.itemsByNumber,
            [pl.contact.phoneNumber]:
            {
              ...pl.contact,
              'hiddenUsers': {},
              lastText: lt
            },
          }
        }
      } else {
        const hiddenUsers = {};

        for (let i = 0; i < pl.contact.hiddenUsers.length; i++) {
          const hu = pl.contact.hiddenUsers[i];
          hiddenUsers[hu.id] = { ...hu }
        }

        return {
          ...state,
          itemsByNumber: {
            ...state.itemsByNumber,
            [pl.contact.phoneNumber]:
            {
              ...pl.contact,
              'hiddenUsers': hiddenUsers,
              lastText: lt
            },
          }
        }
      }
    }
    case ContactActionTypes.GET_CONTACTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    case ContactActionTypes.SET_CURRENT_CONTACT:
      return {
        ...state,
        currentContact: action.payload,
      };
    case ContactActionTypes.UPDATE_CONTACT_START:
      return {
        ...state,
        isUpdatingContact: true,
      };
    case ContactActionTypes.UPDATE_CONTACT_SUCCESS: {
      const pl = action.payload;

      const hiddenUsers = {};
      for (let i = 0; i < pl.hiddenUsers.length; i++) {
        const hu = pl.hiddenUsers[i];
        hiddenUsers[hu.id] = { ...hu }
      }

      return {
        ...state,
        // items: pl,
        // itemsById,
        itemsByNumber: {
          ...state.itemsByNumber,
          [pl.phoneNumber]: {
            ...pl,
            'hiddenUsers': hiddenUsers
          }
        },
        isFetching: false,
        error: null,
      };
    }
    case ContactActionTypes.UPDATE_CONTACT_FAILURE:
      return {
        ...state,
        isUpdatingContact: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default contactReducer;

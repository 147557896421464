import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducer from './user/user.reducer';
import messageReducer from './messages/messages.reducer';
import employeeReducer from './employee/employee.reducer';
import contactReducer from './contact/contact.reducer';
import widgetContactReducer from './widget-contact/widget-contact.reducer';
import widgetMessageReducer from './widget-messages/widget-messages.reducer';
import UIReducer from './ui/ui.reducer';
import sessionReducer from './session/session.reducer';

const authPersistConfig = {
  key: 'error',
  storage: storage,
  whitelist: ['user', 'accessToken']
}

const uiPersistConfig = {
  key: 'ui',
  storage: storage,
  whitelist: ['browserNotifications', 'soundNotifications', 'enterToCall']
}

const sessionPersistConfig = {
  key: 'session',
  storage: storage,
  whitelist: ['isLoggedIn', 'user', 'credentials', 'aws']
}

const rootReducer = combineReducers({
  auth: persistReducer(authPersistConfig, userReducer),
  messages: messageReducer,
  employees: employeeReducer,
  contacts: contactReducer,
  ui: persistReducer(uiPersistConfig, UIReducer),
  widgetContacts: widgetContactReducer,
  widgetMessages: widgetMessageReducer,
  session: persistReducer(sessionPersistConfig, sessionReducer)
});

export default rootReducer;

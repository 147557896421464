import SessionActionTypes from './session.types';
import UserActionTypes from '../user/user.types';

const INITIAL_STATE = {
  isLoggedIn: false,
  credentials: {},
  user: null,
  aws: {}
}

const sessionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SessionActionTypes.SET_SESSION_SUCCESS: {
      return { 
        ...state, 
        user: action.payload.user,
        credentials: action.payload.credentials,
        isLoggedIn: true,
        aws: state.aws
      };
    }
    case UserActionTypes.SIGN_OUT_SUCCESS:
    case SessionActionTypes.CLEAR_SESSION:
    case SessionActionTypes.CLEAR_AWS:
      return INITIAL_STATE;
    case SessionActionTypes.SET_AWS:
      return { 
        ...state, 
        aws: action.payload
      };
    default:
      return state
  }
}

export default sessionReducer
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import moment from 'moment';
import avatar from '../../assets/media/blank-profile-picture.png'

import {
  selectMessagesByUser
} from '../../redux/messages/messages.selectors';

import {
  getContactsTextsStart,
} from '../../redux/messages/messages.actions';

import {
  setShowChatBody,
} from '../../redux/ui/ui.actions';

import {
  selectUser
} from '../../redux/user/user.selectors';

import { selectContactsWithTexts } from '../../redux/contact/contact.selectors';
import { setCurrentNumber } from '../../redux/messages/messages.actions.js';

const MessageWidget = (props) => {
  const [searchText, setSearchText] = useState('');

  const numberClick = (num, id, e) => {
    props.setCurrentNumber(num);
    if (!props.messagesByUser[num]) {
      props.getContactsTextsStart(id, num);
    }

    // Show chat
    document.querySelectorAll('.chat-open').forEach(input => input.parentElement.classList.remove("current"));
    e.currentTarget.parentElement.classList.add('current')
    props.setShowChatBody(true)
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchText(value);
  };

  const showUnread = (b) => {
    if (!b) return '';

    return (<div className="status unread">
      <em className="icon ni ni-bullet-fill"></em>
    </div>)
  }

  const createMessageList = () => {
    const ml = [];

    if (props.contacts && props.currentUser) {
      for (let j = 0; j < props.contacts.length; j++) {

        const contact = props.contacts[j];
        let found = contact.hiddenUsers[props.currentUser.id] ? true : false;
        if (!found && (!searchText || ((contact.phoneNumber && contact.phoneNumber.includes(searchText)) || (contact.name && contact.name.includes(searchText))))) {
          ml.push(
            <li className={`chat-item ${contact.unreadText ? 'is-unread' : ''}`} key={contact.phoneNumber}>
              <a href="#!" className="chat-link chat-open" onClick={numberClick.bind(this, contact.phoneNumber, contact.id)}>
                <div className="chat-media user-avatar">
                  <img src={avatar} alt="" />
                </div>
                <div className="chat-info">
                  <div className="chat-from">
                    <div className="name">{contact.name || 'Unknown Name'} </div>
                    <span className="time">({moment(contact.lastText.createdDate).calendar()})</span>
                  </div>
                  <div className="chat-context">
                    <div className="text">
                      <p>{`${!contact.lastText.incomingMessage ? 'You: ' : 'Them: '}${contact.lastText.input}`}</p>
                    </div>
                    {showUnread(contact.unreadText)}
                  </div>
                </div>
              </a>
            </li>
          );
        } else {
          continue;
        }
      }
    }
    return ml;
  };

  return (
    <div className="nk-chat-aside-body" data-simplebar>
      <div className="nk-chat-aside-search">
        <div className="form-group">
          <div className="form-control-wrap">
            <div className="form-icon form-icon-left">
              <em className="icon ni ni-search"></em>
            </div>
            <input type="text" className="form-control form-round" id="default-03" placeholder="Search by name" onChange={handleSearchChange} />
          </div>
        </div>
      </div>
      <div className="nk-chat-list">
        <h6 className="title overline-title-alt">Messages</h6>
        <ul className="chat-list">
          {createMessageList()}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  contacts: selectContactsWithTexts,
  messagesByUser: selectMessagesByUser,
  // messages: selectMessageItems,
  currentUser: selectUser
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentNumber: (num) => dispatch(setCurrentNumber(num)),
  getContactsTextsStart: (id, phoneNumber) => dispatch(getContactsTextsStart(id, phoneNumber)),
  setShowChatBody: (b) => dispatch(setShowChatBody(b))
});

export default connect(mapStateToProps, mapDispatchToProps)(MessageWidget);

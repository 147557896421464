import { createSelector } from 'reselect';

const selectSessionState = (state) => state.session;

export const selectSession = createSelector([selectSessionState], (session) => session);

export const selectCredentials = createSelector([selectSessionState], (session) => session.credentials);

export const selectAccessToken = createSelector([selectCredentials], (creds) => creds.accessToken);

export const selectIsLoggedIn = createSelector([selectSessionState], (session) => session.isLoggedIn);

export const selectCognito = createSelector([selectSessionState], (session) => session.aws);